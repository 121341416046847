.neos-nodetypes-text {
  &.color-primary {
    color: $brand-primary;
  }

    ul {
        list-style: none;
        li {
            &::before {
                content: "\25AA";
                font-weight: bold;
                display: inline-block;
                width: 19px;
                margin-left: -19px;
            }
        }
    }

    margin-bottom: 26px;
}

.neos-nodetypes-headline {

    h1 {
        margin-bottom: 40px;

        strong {
          font-family: $font-family-medium;
        }
    }

    h2, h3 {
        color: $color-green;
        margin-bottom: 20px;
    }

    &.default {
        h1, h2, h3, h4 {
            color: #414141;
        }
    }

    &.white {
        h1, h2, h3, h4 {
            color: white;
        }
    }

    &.h1 {
        h2, h3 {
          @extend h1;
        }
    }

    &.green {
        h1, h2, h3, h4 {
            color: $color-green;
        }
    }
    
    &.highlightUppercase {
      h1, h2, h3, h4 {
        color: #3A9F55;
        text-transform: uppercase;
        font-size: 23px;
        letter-spacing: 0.1rem;
        font-family: $font-family-medium;
        margin-bottom: 8px;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
      }
    }
    
    &.marginTop {
      h2, h3, h4 {
        margin-top: 50px;
      }
    }

    &#headline {
        position: absolute;
        top: 100px;
        left: calc((100vw - 1370px) / 2);
        width: 540px;

        @media (max-width: 1440px) {
          top: 46px;
          left: 3%;
          width: 464px;
          
          h1 {
            font-size: 34px;
            
            @include media-breakpoint-down(md) {
              font-size: 30px;
              line-height: 34px;
              margin-bottom: 15px;
            }
        
            @include media-breakpoint-down(sm) {
              font-size: 25px;
              line-height: 34px;
              margin-bottom: 15px;
            }
          }
        }

        @include media-breakpoint-down(lg) {
          top: 20px;
          width: auto;
        }

        @include media-breakpoint-down(sm) {
          width: auto;
          left: 5%;
        }
    }

    h2 {
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }

    h3 {
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }
}

p {
    strong {
        font-family: $font-family-medium;
        font-weight: 400;
    }
}

.jonnitto-plyr-video {
  .plyr__poster {
    display: none;
    background-image: unset !important;
  }
}
