.tips--modal {
  display: none;

  &--backend {
    display: block;

    .tips--modal-content {
      position: relative;
      border: 1px solid black;

      .tips--carousel-item {
        display: block;
        height: auto;
      }
    }
  }

  .tips--modal-content {
    overflow:hidden;
    display: inline-table;
    left: 50%;
    width: 100%;
    position: absolute;
    text-align: left;
    top: 0;
    transform: translate(-50%);
    background-color: $color-gray-dark;
    z-index: 1;
  }
}
