input,
textarea {
    width: 100%;

    &::-webkit-input-placeholder {
        /* Edge */
        padding-left: 5px;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        padding-left: 5px;
    }

    &::placeholder {
        padding-left: 5px;
    }
}

.required {
    font-size: $font-size-small;
}

.contact-column {
    @include media-breakpoint-up(sm) {
        @include make-col(8);
    }
}

.contact-name {
    @include media-breakpoint-up(sm) {
        @include make-col(8);
    }
}

@include media-breakpoint-up(sm) {
    .contact-firstname {
        padding-right: 0;
    }
}

.contact-submit {
    @include media-breakpoint-up(md) {
        @include make-col(6);
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {

            button.btn-brand-secondary {
                border-radius: 0;
                text-transform: uppercase;
                padding: 0;
            }
        }
    }
}

.contact-column.contact-dsgvo {
    display: flex;

    label {
        display: flex;
        align-items: top;

        input {
            width: 40px;
            margin-right: 7px;
            margin-top: 3px;

            @include media-breakpoint-up(sm) {
                width: auto;
            }
        }

        p {
            font-size: $font-size-small * 1.2;
        }
    }
}

.contact-confirmation,
.contact-error {
    display: none;
    border: 0;
    border-radius: 0;

    &.alert-success {
        background-color: $brand-primary;
        color: $color-white;
    }

    b {
        font-weight: bold;
    }

    .debug {
        font-size: $font-size-small;
    }
}
