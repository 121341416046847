.culture-section {
  background: linear-gradient(rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.45)), url(https://www.solodev.com/assets/culture.jpg) top no-repeat;
  height: 100%;
  background-size: cover;
  color: #fff;
  text-align: left;
  padding: 250px 0px;
  position: relative;
}

// .culture-section h3 {
//   font-size: 65px;
//   margin: 14px 0;
//   font-family: $font-family-light;
// }
.culture-section p {
  margin: 36px auto;
  font-size: 21px;
  width: 75%;
}
.btn {
  font-size: 18px !important;
}
.btn-slider {
  background-color: #fff;
  color: #d2282e;
  -webkit-transition: all 0.3s ease;
  transition: all 0.1s ease;
  padding: 12px 35px;
}
.btn-slider:hover {
  background-color: #dbdbdb;
  color: #565299;
}

/* Popup CSS Below */
.flexslider .slides img {
margin: 0 auto;
max-width: 100px;
}
.ct-sliderPop {
width:1370px !important;
height: 540px !important;

@include media-breakpoint-down(sm) {
    width: 100vw !important;
  }
}
.ct-sliderPop > .inner > .cow-map-img {
margin-top: 75px;
}
.ct-sectional .col-lg-5.col-lg-offset-1 > img {
cursor: pointer;
border-radius: 50%;
}
.ct-sliderPop .inner {
color: #fff;
margin-top: 110px;

h3 {
  margin-left: 67px;
  margin-bottom: 25px;
}
}
.map-white-border {
border-bottom: 1px solid #fff;
width: 30%;
margin: 50px auto;
}
.ct-sliderPop-container {
display: none;
left: 0px;
opacity: 0;
top: 0px;
}
.ct-sliderPop-container.open {
animation-duration: 0.35s;
animation-fill-mode: both;
animation-name: fadeIn;
display: block;
}
.ct-sliderPop-container {
overflow:hidden;
display: inline-table;
left: 50%;
width: 100%;
position: absolute;
text-align: left;
top: -297px;
transform: translate(-50%, -50%);
background-color: $color-gray-dark;
}

.ct-sliderPop .inner .ct-sliderPop-close {
display: inline-block;
margin: -1% auto 30px;
transition: opacity 0.25s ease-in-out 0s;
width: 75px;
height: 75px;
position: absolute;
right: 0;
top: 37px;
background-repeat: no-repeat;
background-image: url("../Images/layout/close_tip.svg");
}

.ct-sliderPop h2,
.ct-sliderPop p {
font-family: $font-family-light;
font-size: 30px;
line-height: 1;
}
.ct-sliderPop .fa {
  font-size: 84px;
  margin-bottom: 10px;
}
.ct-sliderPop p {
font-size: 21px;
color: white;
padding-bottom: 30px;
width: 90%;
line-height: 30px;
margin: 0 auto;
text-align: left;
}
.ct-sliderPop-container .flex-direction-nav a {
overflow: visible;
position: absolute;
}
.flex-direction-nav a,
.flex-direction-nav a.flex-next::before,
.flex-direction-nav a.flex-prev::before {
color: red;
z-index: 50;
}
.flexslider .slides img {
width: auto !important;
}
.sliderPop-close {
width: 60px;
}


/* =================================
# Media Queries
================================= */
@media (max-width: 1200px){

}
@media (max-width: 991px){

}
@media (max-width: 767px){

.ct-sliderPop {
  height: 500px;
  max-width: 500px !important;
}
.ct-sliderPop > .inner > .cow-map-img {
  margin-top: 50px;
}
.ct-sliderPop-description span {
  font-size: 50px;
}
.ct-sliderPop h2,
.ct-sliderPop-description {
  font-size: 20px;
}
.ct-sliderPop h2 {
  line-height: 0.2;
}
.ct-sliderPop-description {
  padding-bottom: 0;
}
.ct-sliderPop .inner {
  margin-top: 10px;
}
.ct-sliderPop-description .map-fontSize {
  font-size: 80px;
}
}

@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}
@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}

.flex-direction-nav {

  list-style: none;

  .flex-nav-prev {

    a {
      width: 75px;
      height: 75px;
      position: absolute;
      left: 20px;
      top: 50%;
      background-repeat: no-repeat;
      background-image: url("../Images/layout/arrow_tip_right.svg");
    }
  }

  .flex-nav-next {

    a {
      width: 75px;
      height: 75px;
      position: absolute;
      right: 20px;
      top: 50%;
      background-repeat: no-repeat;
      background-image: url("../Images/layout/arrow_tip_right.svg");
      transform: rotate(180deg);
    }
  }
}

.sliderPop {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
