.download-section {
    &.product-info-pumps {
        width: 100%;
        margin-bottom: 0;
        
        .neos-nodetypes-headline {
            border-top: 2px solid $color-gray;
            margin-top: 15px;
            
            h3 {
                color: $color-gray;
                font-family: $font-family-medium;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        
        p {
            width: 100%;
            max-width: 100% !important;
            margin-bottom: 8px;
        }
        
        .pump-specification {
            margin-bottom: 15px;
        }
    }
}

.application-report .pumps-overview {
    .download-collection {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: unset;
        grid-column-gap: 30px;
        margin-top: 17px;
        
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 30px;
        }
        
        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
            grid-row-gap: 17px;
        }
    }
}