.content-circle {
    background-image: url("../Images/layout/startseite_kreis_hell.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(sm) {
      background-image: none;
    }

    &:hover {
        background-image: url("../Images/layout/startseite_kreis_dunkel.svg");
        cursor: pointer;

        @include media-breakpoint-down(sm) {
          background-image: none;
        }
    }
    
    .before, .after {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &.pumps {
        position: absolute;
        right: 43%;
        top: 9%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          right:  53%;
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          right:  53%;
          top:17%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 150px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {
          width: calc(var(--widthLine) - 194px);
          border-bottom:1px solid white;
          position: absolute;
          top: 56%;
          transform: translateX(2px) rotate(156deg);
          transform-origin: left top;
          
          @media (min-width: 2560px) {
            transform: translateX(2px) rotate(168deg)
          }
          
          @media (max-width: 1440px) {
            transform: translateX(2px) rotate(171deg);
          }
        }
        
        .after {
          width: calc(var(--widthLine) - 185px);
          border-bottom: 1px solid #fff;
          position: absolute;
          bottom: 11px;
          transform: translateX(52px) rotate(150deg);
          transform-origin: left top;
          
          @media (min-width: 2560px) {
            width: calc(var(--widthLine) - 179px);
          }
          
          @media (max-width: 1440px) {
            width: calc(var(--widthLine) - 185px);
            transform: translateX(52px) rotate(147deg);
          }
        }
    }

    &.pump-design {
        position: absolute;
        right: 64%;
        top: 26%;
        width: 200px;
        height: 200px;

        @media (min-width: 2560px) {
          right:77%;
        }

        @media (max-width: 1440px) {
          right: 82%;
        }

        @include media-breakpoint-down(lg) {
          left: 3%;
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          right: auto;
          left: 3%;
          top: 17%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 215px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {
          width: calc(var(--widthLine) - 193px);
          border-bottom:1px solid white;
          position: absolute;
          top: 97%;
          transform: translateX(133px) rotate(44deg);
          transform-origin: left top;
          
          @media (min-width: 2560px) {
            width: calc(var(--widthLine) - 180px);
            transform: translateX(133px) rotate(34deg);
          }
          
          @media (max-width: 1440px) {
            display: none;
          }
        }
        
        .after {
          width: calc(var(--widthLine) - 185px);
          border-bottom:1px solid white;
          position: absolute;
          top: 70%;
          transform: translateX(191px) rotate(16deg);
          transform-origin: left top;
        }
    }

    &.pumped-media {
        position: absolute;
        right: 54%;
        top: 55%;
        width: 200px;
        height: 200px;

        @media (min-width: 2560px) {
          right: 60%;
        }

        @media (max-width: 1440px) {
          right: 54%;
          top: 45%;
        }

        @include media-breakpoint-down(lg) {
          right: 53%;
          top: 58%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 280px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {
          width: calc(var(--widthLine) - 195px);
          border-bottom:1px solid white;
          position: absolute;
          top: 56%;
          transform: translateX(198px) rotate(-12deg);
          transform-origin: left top;
          
          @media (min-width: 2560px) {
            transform: translateX(198px) rotate(-3deg);
          }
          
          @media (max-width: 1440px) {
            display: none;
          }
        }
        
        .after {
          width: calc(var(--widthLine) - 196px);
          border-bottom:1px solid white;
          position: absolute;
          top: 20%;
          transform: translateX(179px) rotate(-27deg);
          transform-origin: left top;
          
          @media (min-width: 2560px) {
            transform: translateX(179px) rotate(-22deg);
          }
          
          @media (max-width: 1440px) {
            width: calc(var(--widthLine) - 194px);
            transform: translateX(179px) rotate(-19deg);
          }
        }
    }

    &.hermetic {
        position: absolute;
        right: 32%;
        top: 52%;
        width: 200px;
        height: 200px;

        @media (max-width: 1440px) {
          right: 34%;
          top: 62%;
        }

        @include media-breakpoint-down(lg) {
          right: 27%;
          top: 58%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 345px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {
          width: calc(var(--widthLine) - 185px);
          border-bottom:1px solid white;
          position: absolute;
          top: 9%;
          transform:translateX(156px) rotate(-54deg);
          transform-origin: left top;
          
          @media (min-width: 2560px){
            transform: translateX(156px) rotate(-61deg);
          }
        }

        .after {
          width: calc(var(--widthLine) - 178px);
          border-bottom:1px solid white;
          position: absolute;
          top: 43%;
          transform: translateX(198px) rotate(-40deg);
          transform-origin: left top;
          
          @media (max-width: 1440px) {
            width: calc(var(--widthLine) - 156px);
            top: 48%;
            transform: translateX(198px) rotate(-43deg);
          }
        }
    }

    &.applications {
        position: absolute;
        right: 26%;
        top: 24%;
        width: 200px;
        height: 200px;

        @media (max-width: 1440px) {
          right: 24%;
        }

        @include media-breakpoint-down(lg) {
          top: 17%;
          right: 27%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          top: 17%;
          right: 27%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 410px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .after {
          width: calc(var(--widthLine) - 197px);
          border-bottom:1px solid white;
          position: absolute;
          top: 39%;
          left: -197px;
          transform: translateX(394px) rotate(-24deg);
          transform-origin: 0px;
        }
        
        .before {
          width: calc(var(--widthLine) - 197px);
          border-bottom:1px solid white;
          position: absolute;
          top: 40%;
          left: -197px;
          transform: translateX(198px) rotate(-150deg);
          transform-origin: 0px;
        }
    }

    &.contact {
        position: absolute;
        right: 6%;
        top: 55%;
        width: 200px;
        height: 200px;

        @media (max-width: 1440px) {
          right: 6%;
          top: 60%;
        }

        @include media-breakpoint-down(lg) {
          right: 3%;
          top: 58%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 540px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {
          width: calc(var(--widthLine) - 197px);
          border-bottom:1px solid white;
          position: absolute;
          top: 44px;
          transform: translateX(17px) rotate(221deg);
          transform-origin: 0px;
        }
        
        .after {
          width: calc(var(--widthLine) - 199px);
          border-bottom: 1px solid white;
          position: absolute;
          top: 49%;
          left: -197px;
          transform: translateX(197px) rotate(-176deg);
          transform-origin: 0px;
        }
    }

    &.training {
        position: absolute;
        right: 3%;
        top: 6%;
        width: 200px;
        height: 200px;

        @include media-breakpoint-down(lg) {
          right: 3%;
          top: 17%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          right: 3%;
          top: 17%;
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: 475px;
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {
          width: calc(var(--widthLine) - 199px);
          border-bottom:1px solid white;
          position: absolute;
          top: 49%;
          left: -197px;
          transform: translateX(197px) rotate(-183deg);
          transform-origin: 0px;
        }
        
        .after {
          width: calc(var(--widthLine) - 197px);
          border-bottom:1px solid white;
          position: absolute;
          bottom: 0%;
          left: -100px;
          transform: translateX(197px) rotate(103deg);
          transform-origin: 0px;
        }
    }

    &.pump-technology {
        position: absolute;
        left: 13%;
        bottom: 26%;
        width: 200px;
        height: 200px;
        
        @media (min-width: 2560px) {
          left: 9%;
          bottom: 23%;
        }

        @media (max-width: 1440px) {
          bottom: 9%;
        }
        
        @media (max-width: 1200px) {
          left: 8%;
          bottom: 2%;
        }

        @include media-breakpoint-down(lg) {
          left: 3%;
          top: 58%;
          width: 180px;
          height: 180px;
        }

        @include media-breakpoint-down(md) {
          width: 150px;
          height: 150px;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          width: auto;
          height: auto;
          top: calc(540px + 65px);
          left:0;
          right: 0;
          display: block;
          margin: 0 17px;
        }
        
        .before {          
          width: calc(var(--widthLine) - 199px);
          border-bottom: 1px solid #fff;
          position: absolute;
          top: 15%;
          left: -31px;
          transform: translateX(202px) rotate(-44deg);
          transform-origin: 0px;
          
          @media (min-width: 2560px) {
            width: calc(var(--widthLine) - 187px);
            transform: translateX(202px) rotate(-73deg);
          }
          
          @media (max-width: 1440px) {
            width: calc(var(--widthLine) - 191px);
            left: 0;
            transform: translateX(29px) rotate(-116deg);
          }
          
          @media (max-width: 1200px) {
            width: calc(var(--widthLine) - 179px);
          }
        }
        
        .after {
          width: calc(var(--widthLine) - 177px);
          border-bottom: 1px solid #fff;
          position: absolute;
          top: 75%;
          transform: translateX(186px) rotate(-372deg);
          transform-origin: 0px;
          
          @media (max-width: 1440px) {
            width: calc(var(--widthLine) - 186px);
            top: 42%;
            transform: translateX(199px) rotate(-379deg);
          }
          
          @media (max-width: 1200px) {
            width: calc(var(--widthLine) - 189px);
          }
        }
    }

   .pumps {
        background-image: url("../Images/layout/startseite_pumpentypen.svg");
        background-repeat: no-repeat;
        background-size: 70% 70%;
        background-position-x: center;
        position: relative;
        top: 15px;
        height: 209px;
        width: 200px;

        @include media-breakpoint-down(lg) {
          background-size: 58% 100%;
          width: auto;
          height: 100px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .pump-design {
        background-image: url("../Images/layout/startseite_pumpenauslegung.svg");
        background-repeat: no-repeat;
        background-size: 68% 68%;
        background-position-x: center;
        position: relative;
        top: 15px;
        height: 190px;
        width: 200px;

        @include media-breakpoint-down(lg) {
          background-size: 58% 100%;
          width: auto;
          height: 100px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 92%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }

        p {
            top: 115px;

            @include media-breakpoint-down(lg) {
              top: 90px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: auto;
            }
        }
    }

    .pumped-media {
        background-image: url("../Images/layout/startseite_foerdermedien.svg");
        background-repeat: no-repeat;
        background-size: 57% 68%;
        background-position-x: center;
        position: relative;
        top: 15px;
        height: 190px;
        width: 200px;

        @include media-breakpoint-down(lg) {
          background-size: 47% 100%;
          width: auto;
          height: 100px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .hermetic {
        background-image: url("../Images/layout/startseite_unternehmen_hermetic.svg");
        background-repeat: no-repeat;
        background-size: 68% 68%;
        background-position-x: center;
        position: relative;
        top: 26px;
        height: 139px;
        width: 200px;


        @include media-breakpoint-down(lg) {
          background-size: 58% 100%;
          width: auto;
          height: 70px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }

        p {
            top: 111px;

            @include media-breakpoint-down(lg) {
              top: 90px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: 0;
            }
        }
    }

    .applications {
        background-image: url("../Images/layout/startseite_applikationen.svg");
        background-repeat: no-repeat;
        background-size: 40% 68%;
        background-position-x: center;
        position: relative;
        top: 15px;
        height: 190px;
        width: 200px;

        @include media-breakpoint-down(lg) {
          background-size: 32% 100%;
          width: auto;
          height: 100px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .contact {
        background-image: url("../Images/layout/startseite_kontakt.svg");
        background-repeat: no-repeat;
        background-size: 34% 68%;
        background-position-x: center;
        position: relative;
        top: 15px;
        height: 190px;
        width: 200px;

        @include media-breakpoint-down(lg) {
          background-size: 28% 100%;
          width: auto;
          height: 100px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }
    }

    .training {
        background-image: url("../Images/layout/startseite_tipps_training.svg");
        background-repeat: no-repeat;
        background-size: 42% 68%;
        background-position-x: center;
        position: relative;
        top: 15px;
        height: 155px;
        width: 200px;

        @include media-breakpoint-down(lg) {
          background-size: 38% 100%;
          width: auto;
          height: 100px;
        }

        @include media-breakpoint-down(md) {
          background-size: 100% 100%;
          top: 32px;
          width: auto;
          height: 33px;
        }

        @include media-breakpoint-down(sm) {
          background-image: none;
          background-color: rgba($color: #000000, $alpha: 0.4);
          top: 0;
          height: auto;
          padding: 13px 0;
        }

        p {
            top: 111px;

            @include media-breakpoint-down(lg) {
              top: 90px;
            }

            @include media-breakpoint-down(md) {
              top: 45px;
            }

            @include media-breakpoint-down(sm) {
              top: 0;
            }
        }
    }
    
    .pump-technology {
      background-image: url("../Images/layout/startseite_pumpentechnologien.svg");
      background-repeat: no-repeat;
      background-size:  49% 78%;
      background-position-x: center;
      position: relative;
      top: 15px;
      height: 155px;
      width: 200px;

      @include media-breakpoint-down(lg) {
        background-size: 38% 100%;
        width: auto;
        height: 100px;
      }

      @include media-breakpoint-down(md) {
        background-size: 100% 100%;
        top: 32px;
        width: auto;
        height: 33px;
      }

      @include media-breakpoint-down(sm) {
        background-image: none;
        background-color: rgba($color: #000000, $alpha: 0.4);
        top: 0;
        height: auto;
        padding: 13px 0;
      }

      p {
          top: 107px;

          @include media-breakpoint-down(lg) {
            top: 90px;
            word-break: break-word;
            hyphens: auto;
            margin: 0 22px;
          }

          @include media-breakpoint-down(md) {
            top: 45px;
          }

          @include media-breakpoint-down(sm) {
            top: 0;
          }
      }
  }    

    p {
        position: relative;
        top: 126px;
        text-align: center;
        $font-family-medium: 'Vialog LT W02 Medium', Tahoma, Arial,sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px;
        line-height: 20px;

        @include media-breakpoint-down(lg) {
          top: 90px;
          font-size: 16px;
        }

        @include media-breakpoint-down(md) {
          top: 45px;
          font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
          top: 0;
          margin-bottom: 0;
          height: auto;
          font-size: 20px;
          font-family: $font-family-medium;
        }
    }
}

.content-circle {
    &.active {

    }
}

.content-circle-popup {
    display: none;
        &.active {
           display: block;

           @include media-breakpoint-down(md) {
            display: none;
          }

           &#pumpentypen-content {
                // position: absolute;
                // right: -192px;
                // top: -25px;

            p {
                text-align: left;
                text-transform: none;
                position: relative;
                right: -130px;
                top: -110px;
                width: 289px;
                padding: 20px 20px 50px 78px;
                line-height: 26px;
                z-index: 0;

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 289px;
                  height: 141px;
                  z-index: -1;
                  background-image: url("../Images/layout/circle-hover-bg.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                &::after {
                  content: '';
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  z-index: -1;
                  margin-left: 10px;
                  margin-top: 5px;
                  background-image: url("../Images/layout/arrow-circle-content.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }
            }
           }

           &#pumpentypen-content {
                // position: absolute;
                // right: -192px;
                // top: -25px;

            p {
                text-align: left;
                text-transform: none;
                position: relative;
                right: -130px;
                top: -110px;
                width: 289px;
                padding: 20px 20px 50px 78px;
                line-height: 26px;
                z-index: 0;

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 289px;
                  height: 141px;
                  z-index: -1;
                  background-image: url("../Images/layout/circle-hover-bg.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                &::after {
                  content: '';
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  z-index: -1;
                  margin-left: 10px;
                  margin-top: 5px;
                  background-image: url("../Images/layout/arrow-circle-content.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }
            }
           }

           &#pump-design-content {

            p {
                text-align: left;
                text-transform: none;
                position: relative;
                right: 220px;
                top: 66px;
                width: 289px;
                padding: 20px 78px 50px 20px;
                line-height: 26px;
                z-index: 0;

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 289px;
                  height: 141px;
                  z-index: -1;
                  transform:rotate(180deg);
                  background-image: url("../Images/layout/circle-hover-bg.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                &::after {
                  content: '';
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  z-index: -1;
                  margin-left: 10px;
                  margin-top: 5px;
                  background-image: url("../Images/layout/arrow-circle-content.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                }
            }
        }

        &#pumping-media-content {

          p {
              text-align: left;
              text-transform: none;
              position: relative;
              right: -132px;
              top: 64px;
              width: 289px;
              padding: 20px 20px 20px 95px;
              line-height: 26px;
              z-index: 0;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 289px;
                height: 141px;
                z-index: -1;
                transform:scaleY(-1);
                background-image: url("../Images/layout/circle-hover-bg.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }

              &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                z-index: -1;
                margin-left: 10px;
                margin-top: 5px;
                background-image: url("../Images/layout/arrow-circle-content.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }
          }
      }

      &#hermetic-content {

        p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: -130px;
            top: 55px;
            width: 289px;
            padding: 20px 20px 20px 95px;
            line-height: 26px;
            z-index: 0;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 289px;
              height: 141px;
              z-index: -1;
              transform:scaleY(-1);
              background-image: url("../Images/layout/circle-hover-bg.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }

            &::after {
              content: '';
              position: absolute;
              width: 17px;
              height: 17px;
              z-index: -1;
              margin-left: 10px;
              margin-top: 5px;
              background-image: url("../Images/layout/arrow-circle-content.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
        }
      }

      &#applications-content {

          p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: -130px;
            top: 66px;
            width: 289px;
            padding: 20px 20px 20px 95px;
            line-height: 26px;
            z-index: 0;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 289px;
                height: 141px;
                z-index: -1;
                transform:scaleY(-1);
                background-image: url("../Images/layout/circle-hover-bg.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }

              &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                z-index: -1;
                margin-left: 10px;
                margin-top: 5px;
                background-image: url("../Images/layout/arrow-circle-content.svg");
                background-size: contain;
                background-repeat: no-repeat;
              }
          }
      }

      &#contact-content {

        p {
            text-align: left;
            text-transform: none;
            position: relative;
            right: 221px;
            top: 64px;
            width: 289px;
            padding: 20px 78px 50px 20px;
            line-height: 26px;
            z-index: 0;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 289px;
              height: 141px;
              z-index: -1;
              transform:rotate(180deg);
              background-image: url("../Images/layout/circle-hover-bg.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }

            &::after {
              content: '';
              position: absolute;
              width: 17px;
              height: 17px;
              z-index: -1;
              margin-left: 10px;
              margin-top: 5px;
              background-image: url("../Images/layout/arrow-circle-content.svg");
              background-size: contain;
              background-repeat: no-repeat;
            }
        }
      }

      &#training-content {

        p {
          text-align: left;
          text-transform: none;
          position: relative;
          right: 221px;
          top: 65px;
          width: 289px;
          padding: 20px 78px 50px 20px;
          line-height: 26px;
          z-index: 0;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 289px;
            height: 141px;
            z-index: -1;
            transform:rotate(180deg);
            background-image: url("../Images/layout/circle-hover-bg.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }

          &::after {
            content: '';
            position: absolute;
            width: 17px;
            height: 17px;
            z-index: -1;
            margin-left: 10px;
            margin-top: 5px;
            background-image: url("../Images/layout/arrow-circle-content.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      
      &#pump-technology-content {

        p {
          text-align: left;
          text-transform: none;
          position: relative;
          right: -132px;
          top: 64px;
          width: 289px;
          padding: 20px 20px 20px 95px;
          line-height: 26px;
          z-index: 1;
          margin-left: 0;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 289px;
            height: 141px;
            z-index: -1;
            transform:scaleY(-1);
            background-image: url("../Images/layout/circle-hover-bg.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }

          &::after {
            content: '';
            position: absolute;
            width: 17px;
            height: 17px;
            z-index: -1;
            margin-left: 10px;
            margin-top: 5px;
            background-image: url("../Images/layout/arrow-circle-content.svg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
}

.circle-content-backend {
    .pumps, .pump-design, .pumped-media, .hermetic, .applications, .contact , .training, .pump-technology{
        position: relative;
        right: 0;
        left: 0;
        top: auto;
        p {
            color: black;
            top: 0;
            text-align: left;
            width: 300px;
        }
    }
}
