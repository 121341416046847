.tip-section {
    @extend .col;
    width: 250px;
    height: 226px;
    background-color: $color-gray-dark;
    padding: 10px;
    display: inline-grid;
    margin-right: 21px;
    margin-bottom: 21px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      width: 200px;
    }

    &:nth-child(5) {
        margin-right: 0;

        @media (max-width: 1240px) {
          margin-right: 21px;
        }
    }

    &:nth-child(10) {
        margin-right: 0;
    }

    div {
        text-align: center;

        p {
            color: white;
        }

        &:first-child {
            padding-top: 45px;
            p {
                font-size: 80px;
                margin-bottom: 0;
                height: 75px;
            }
        }

        &:last-child {
            p {
                font-size: 22px;
                height: 80px;
                line-height: 30px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      width: 100%;
    }
}

.tip-section.backend {
        div {
        &:first-child {
            padding-top: 0;
        }
    }
}
