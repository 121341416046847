.carousel.case-studies-slider {
    .carousel-inner {
        overflow: visible;
        
        .slick-track {
            padding-bottom: 50px;
        }
        
        .slick-prev, .slick-next {
            z-index: 1;
            height: 70px;
            width: 28px;
            
            @media (max-width: 1440px) {
                width: 41px;
            }
            
            @include media-breakpoint-down(sm) {
                top: 28%;
            }
        }
        
        .slick-prev {
            left: -85px;
            
            @media (max-width: 1524px) {
                left: -37px;
            }
            
            @media (max-width: 1440px) {
                left: 2px;
            }
            
            @include media-breakpoint-down(sm) {
                left: -4px;
            }
        }
        
        .slick-next {
            right: -85px;
            
            @media (max-width: 1524px) {
                right: -37px;
            }
            
            @media (max-width: 1440px) {
                right: 2px;
            }
            
            @include media-breakpoint-down(sm) {
                right: -4px;
            }
        }
        
        .slick-prev::before, .slick-next::before {
            background-size: contain;
            max-width: 70px;
            height: 70px;
            opacity: 1;
            position: relative;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 1;
            
            @include media-breakpoint-down(sm) {
                max-width: 50px;
                height: 50px;
            }
        }
        
        .slick-prev::before {
            content: '';
            background-image: url("../Images/layout/pfeil-grau-links-dunkel.svg");
        }
        
        .slick-next::before {
            content: '';
            background-image: url("../Images/layout/pfeil-grau-links-dunkel.svg");
            transform: rotate(180deg);
        }
        
        .slick-list.draggable {
            overflow-y: visible;
        }
        
        .slick-slide {
            margin: 0 15px;
            
            @include media-breakpoint-down(sm) {
                margin: 0 8px;
            }
            
            .slick-arrow::before {
                z-index: 99;
            }
        }
        
        .slick-dots {
            li {
                width: auto;
                
                button {
                    height: 3px;
                    margin-right: 3px;
                    margin-left: 3px;
                    
                    &::before {
                        content: '';
                        width: 30px;
                        height: 3px;
                        background-color: $color-gray;
                    }
                }
            }
        }
    }
}

.case-studies-slider-backend {
    margin-bottom: 20px;
    
    .carousel-inner {
        overflow: visible;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    
    .case-study--item {
        box-shadow: 0px 4px 11px #00000029;
    }
}