.newsletter {
    padding-left: 17px;
    padding-right: 17px;
    
    .newsletters-headline {
        h2 {
            margin-top: 0;
            margin-bottom: 5px;
            text-align: left;

            font-family: Vialog LT W02 Light, Tahoma, Arial, sans-serif;
            font-style: normal;
            font-variant: normal;
            font-weight: 300;
            font-size: 30px;
            line-height: 35.2px;

            letter-spacing: 0.3px;
            color: #212529;

            strong {
                font-weight: 500;
            }
        }
        h3 {
            margin-top: 0;
            margin-bottom: 0px;
            text-align: left;

            font-family: Vialog LT W02 Light, Tahoma, Arial, sans-serif;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            padding-top: 3px;

            letter-spacing: 0.4px;
            color: #464646;
        }
    }
    .neos-nodetypes-html {
        iframe {
            height: 160px;
            @media (max-width: 1145px) {
                height: 235px;
            }
            @media (max-width: 991px) {
                height: 255px;
            }
            @media (max-width: 882px) {
                height: 310px;
            }
            @media (max-width: 767px) {
                height: 340px;
            }
            @media (max-width: 510px) {
                height: 385px;
            }

            @media (max-width: 407px) {
                height: 410px;
            }
        }
    }
}