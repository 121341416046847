.btn-brand-primary {
    @include media-breakpoint-down(sm) {
      padding: 12px 20px;
    }
    background-color: $brand-primary;
    color: $color-white !important;
    padding: 12px 30px;
    border-radius: 5px;
    font-family: $font-family-medium;
    letter-spacing: 1px;
    font-size: 1rem;
    text-transform: uppercase;
    height: auto;
    cursor: pointer;
    display: inline-block;

    &:hover {
      text-decoration: none;
      background-color: lighten($brand-primary, 4%);
      color: $white;
    }
  }

  .btn-brand-secondary {
    @extend .btn-brand-primary;
    background-color: $color-gray;
    color: $white !important;

    &:hover {
      text-decoration: none;
      background-color: $brand-primary;
      color: $white;
    }
  }

  .btn-white {
    @extend .btn-brand-primary;
    background-color: $color-white;
    color: $color-gray-dark !important;

    &:hover {
      text-decoration: none;
      background-color: #E8E8E8;
      color: $black;
    }
  }

  .btn-brand-primary, .btn-brand-secondary {

    &.inline-block {
      @include media-breakpoint-down(sm){
        display: block;
        margin-bottom: 20px;
        margin-right: 0;
        text-align: center;
      }
      display: inline-block;
      height: 44px;
      margin-right: 25px;
      padding: 9px 30px;

      &.mt25 {
        margin-top: 25px;
      }

      &.mt50 {
        margin-top: 50px;
      }

      &.mb25 {
        margin-bottom: 25px;
      }

      &.mb50 {
        margin-bottom: 50px;
      }
    }
  }
