body {
  > .content {
    @extend .container-fluid;
    margin: 0;
    padding: 0;
    min-height: 80vh;

    img {
      @extend .img-fluid;
    }

    ul, ol {
      padding-left: 20px;

      li {
          @extend p;
          margin-bottom: 8px;
      }
    }

    table {
      @extend .table;
    }
  }
}

p {
    font-size: 21px;
    color: #212529;
    line-height: 30px;

    @include media-breakpoint-down(md){
      font-size: 20px;
      line-height: 30px;
  }

    @include media-breakpoint-down(sm){
        font-size: 18px;
        line-height: 33px;
    }
}

h1 {
    @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 15px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 15px;
    }
}

h2 {
    @include media-breakpoint-down(sm) {
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 10px;
    }
}

h3 {
  margin-left: 0;
    @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    
    &.line {
      position: relative;
      padding-bottom: 12px;
      margin-bottom: 26px;
      color: $color-gray;
      font-family: $font-family-medium;
      line-height: 36px;

      @include media-breakpoint-down(lg) {
          line-height: 33px;
      }

      @include media-breakpoint-down(md) {
          line-height: 32px;
      }

      &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-top: 1px solid #707070;
      }
  }
}

.neos-contentcollection {
  @include clearfix;
}

.h3extra {
  color: #3A9F55;
  text-transform: uppercase;
  font-size: 23px;
  letter-spacing: 0.1rem;
  font-family: $font-family-medium;
  margin-top: 23px;

  @include media-breakpoint-down(sm) {
      font-size: 20px;
  }
}

.back {
  position: absolute;
  right: calc((100vw - 1370px) / 2);
  top: 25px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 99;
  
  @media (max-width: 1440px) {
    right: 55px;
  }
  
  @include media-breakpoint-down(sm) {
    right: 15px;
    top: 15px;
  }
  
  span {
    display: block;
    position: absolute;
    top: 20px;
    width: 40px;
    height: 3px;
    border-radius: 1px;
    background-color: #000;
    transform: rotate(45deg);
    cursor: pointer;
    
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}

.btn-primary {
  @extend .btn-brand-primary;
}