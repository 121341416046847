.contact-person {
    display: flex;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .contact-person-content {
        @include make-col(9);
        @extend .col;

        p {
            color: white;

            &:first-child {
                font-size: 25px;
                text-transform: uppercase;
                font-family: $font-family-medium;

                @include media-breakpoint-down(sm) {
                  font-size: 22px;
                  margin-bottom: 0;
              }
            }

            &:nth-child(2), &:nth-child(3) {
                font-size: 22px;
                color: white;

                @include media-breakpoint-down(md){
                  font-size: 20px;
                  line-height: 30px;
              }

                @include media-breakpoint-down(sm){
                    font-size: 18px;
                    line-height: 33px;
                }
            }

            &:nth-child(3) {
                margin-bottom: 40px;
            }

            a {
              color: white;
            }
        }

        @include media-breakpoint-down(md) {
          @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
          @include make-col(12);
        }
    }

    .backend {
        .contact-person-content {
            p {
                color: white;
                text-transform: none;
                font-family: $font-family-light;
            }
        }
    }

    .contact-person-image {
        @include make-col(3);
        @extend .col;

        @include media-breakpoint-down(md) {
          @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
          @include make-col(12);
        }
    }

    &.left {
        .content-right {
            float: right;
            position: relative;
            right: 75%;

            @include media-breakpoint-down(md) {
              right: 50%;
            }

            @include media-breakpoint-down(sm) {
              right: 0;
            }
        }

        .content-left {
            float: left;
            position: relative;
            left: 28%;

            @include media-breakpoint-down(md) {
              left: 50%;
            }

            @include media-breakpoint-down(sm) {
              left: 0;
            }
        }
    }
}
