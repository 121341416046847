.highlight--wrapper {
  height: 710px;
  width: 100%;
  background-size: cover;
  background-position: center;

    @include media-breakpoint-down(md) {
        background-position: center
    }

    @include media-breakpoint-down(sm) {
        height: 810px;
    }
}
