#content-slider.hero-pump--slider.carousel {

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }

  .hero-pump-content {
      .container {
        @media (max-width: 1817px) {
            padding-top: 118px;
        }
      }
  }

  .carousel-indicators {
    display: flex;
    position: relative;
    top: 0;
    z-index: 0;
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      display: contents;
    }

    li {
      max-width: 170px;
      text-align: center;
      font-size: 18px;
      width: auto;
      text-indent: 0;
      background: none;
      padding: 15px 15px 0;
      line-height: 1.35;
      position: relative;
      margin-right: 23px;

      &:last-child {
          margin-right: 0;
      }
      @media (max-width: 1200px) {
        padding: 15px 11px 0;
        font-size: 14px;
        line-height: 20px;
        margin-right: 0px;
      }

      @include media-breakpoint-down(md) {
        padding-top: 10px;
        height: 18px;
        max-width: 100%;
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 50px;
        height: 3px;
        transition: 0.3s;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-down(md) {
            transition: unset;
        }
      }

      &.active {
          &::before {
              content: '';
              position: absolute;
              top: 0;
              width: 50px;
              height: 3px;
              background-color: $color-green;


              @include media-breakpoint-down(md) {
                width: 100px;
              }
          }
      }

      &:hover {
          position: relative;
          opacity: 1;
          &::before {
              content: '';
              position: absolute;
              top: 0;
              width: 50px;
              height: 3px;
              background-color: $color-green;
              transition: width 0.3s ease-in-out;

              @include media-breakpoint-down(md) {
                transition: unset;
              }

              @include media-breakpoint-down(md) {
                width: 100px;
              }
          }
      }
    }

    ~ .neos-contentcollection {
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }
  }

  .carousel-control-prev {
    .carousel-control-prev-icon {
        left: 185px;
        top: 765px;

        @media (max-width: 1817px) {
            top: 724px;
            left: 460px;
        }

        @media (max-width: 1200px) {
            top: 695px;
        }

        @include media-breakpoint-down(lg) {
            top: 672px;
            left: 460px;
        }

        @include media-breakpoint-down(md) {
            top: 724px;
            left: 335px;
        }

        @media (max-width: 767px) {
            top: 647px;
        }

        @media (max-width: 576px) {
            top: 642px;
            left: 220px;
        }

        @media (max-width: 425px) {
            height: 40px;
            top: 618px;
            left: 150px;
        }

        @media (max-width: 375px){
            top: 630px;
        }

        @media (max-width: 320px) {
          top: 620px;
          left: 100px;
        }
    }
}

  .carousel-control-next {
      .carousel-control-next-icon {
          right: 185px;
          top: 765px;

          @media (max-width: 1817px) {
            top: 724px;
            right: 460px;
          }

          @media (max-width: 1200px) {
            top: 695px;
          }

          @include media-breakpoint-down(lg) {
            top: 672px;
            right: 460px;
          }

          @include media-breakpoint-down(md) {
            top: 724px;
            right: 335px;
          }

          @media (max-width: 767px) {
            top: 647px;
          }

          @media (max-width: 576px) {
            top: 642px;
            right: 220px;
          }

          @media (max-width: 425px) {
            height: 40px;
            top: 618px;
            right: 150px;
          }

          @media (max-width: 375px){
            top: 630px;
          }

          @media (max-width: 320px) {
            top: 620px;
            right: 100px;
          }
      }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
      width: 70px;
      position: absolute;
      background-repeat: no-repeat;
      background-image: url("../Images/layout/pfei-grau-links.svg") !important;
  }
}
