.form-title {
  @extend %form-title;
}

.form-subtitle {
  h4 {
    background-color: #ecf5ec;
    padding: 0 25px 5px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

form#form {
  padding-top: 20px;

  h2 {
    width: 100%;
    color: $white;
    margin-top: 0.5rem;
  }

  p {
    color: white;
  }

  .help-inline {
    font-size: $font-size-small;
    color: red;
  }

  .form-columns {
    .col-sm-5 {
      .form-group.contact-column {
        @include make-col(12);
      }
    }
  }

  .form-group {
    &.contact-column {
      @include make-col(12);
    }
  }

  .submit {
    button {
      @extend .btn-brand-primary;
      @include make-col(5);
      border: none;
    }
  }
}

.grid-content-col.col-12 {
  &.hubspot-form {
    form {
      display: block !important;

      input[type="text"],
      input[type="email"],
      input[type="tel"]  {
        @include make-col(12);
        width: 100%;
        height: 45px;
        border: none;
        box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.15);
        font-size: 20px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 0;
      }

      &#booklet-form {

        .required {
          input {
            &::placeholder {
              color:rgb(203, 51, 51);
              font-style: italic;
            }
          }
        }

        input {
          &::placeholder {
            color:#a09c9c;
            font-style: italic;
          }
        }
      }

      label,
      label p {
        color: white;
        @include media-breakpoint-down(sm) {
          max-width: 100%;
          width: 100%;
        }
      }

      select {
        border-radius: 0;
        height: 45px;
        width: 100%;
        padding-left: 8px;
        border-right: solid white 8px;
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
      }

      .a-form-input, .a-form-select {
        margin-bottom: 15px;

        label {
          margin-bottom: 4px;
          text-transform: uppercase;
          letter-spacing: .02em;
        }
      }

      .a-form-checkbox {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 25px;

        input {
          display: flex;
          box-shadow: none;
          width: 15px;
          height: 15px;
          margin-top: 10px;
          margin-right: 15px;
        }
      }

      .a-form-checkbox--required {
        label {
          display: block;
        }
      }

      .a-form-component, .a-form-checkbox {
        p {
          color: white;
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 8px;

          a {
            color: white;
            text-decoration: underline;
          }
        }
      }

      .some-class .some-class.some-class--submit,
      .submit .btn.btn-primary {
          @extend .btn-white;
          border: none;
          margin-top: 18px;
      }
    }

    .form-navigation {
      padding-left: 0;

      .next.submit {
        list-style-type: none;
      }
    }

    .neos-nodetypes-text  {
      p {
        color: white;
      }
    }

    .sone-class__content > fieldset > fieldset:last-child {
      display: none;
    }
  }
}
