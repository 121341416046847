#content-slider {
    @extend .container-fluid;
    padding-left: 0;
    padding-right: 0;

    .carousel-item {
        height: auto;
    }

    ol {
        display: none;
    }

    .hero-pump-header {
        @extend .container;
        position: relative;
        margin-bottom: 40px;

        @include media-breakpoint-down(sm) {
            margin-top: 15px;
        }

        h2 {
            color: $color-green;
            margin-bottom: 20px;

            span {
                display: block;
            }
        }

        p {
            display: block;
        }

        img {
            @include make-col(4);
        }

        figure {
            text-align: center;
        }
    }

    .hero-pump-content {

        background-color: $color-gray-dark;

        .container {
            display: flex;
            padding-right: 0;
            padding-left: 0;
            padding-top: 50px;
            padding-bottom: 50px;

            @include media-breakpoint-down(md) {
                display: block;
                padding-top: 74px;

                .column-two {
                    margin-top: 50px;
                }
            }
        }

        .column-one, .column-two  {
            // @extend .container;
          @include make-col(6);
          @extend .col;
          color: white;

          @include media-breakpoint-down(xs) {
            @include make-col(12);
          }

          h3 {
              color: #3A9F55;
              text-transform: uppercase;
              font-size: 23px;
              letter-spacing: 0.1rem;
              font-family: $font-family-medium;
              margin-top: 23px;

              @include media-breakpoint-down(sm) {
                  font-size: 20px;
              }
          }
        }

        .column-one {

          @include media-breakpoint-down(md) {
            @include make-col(12);
        }

            div {
                margin-bottom: 50px;
                p, li {
                    color: white;
                }

                h3 {
                  &:first-child {
                    margin-top: 0 !important;
                  }
                }
            }

            ul {
                list-style: none;
                li {
                    &::before {
                        content: "\25AA";
                        font-weight: bold;
                        display: inline-block;
                        width: 19px;
                        margin-left: -19px;
                    }
                    @include media-breakpoint-down(md) {
                        margin-bottom: 2px;
                    }

                    a {
                      color: white;
                      text-decoration: none;
                      position: relative;

                      &:hover {
                        color: #F5F5F5;
                      }
                    }

                    a::after {
                      content: '';
                      position: absolute;
                      bottom: 2px;
                      left: 0;
                      display: inline-block;
                      width: 100%;
                      border-bottom: 1px solid white;
                     }
                }
            }
        }

        .column-two {

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            .videos {
                margin-bottom: 33px;
            }

            .downloads--assets {
                display: flex;

                @include media-breakpoint-down(sm) {
                  display: block;
              }

                .download-asset {
                    @include make-col(4);
                    margin-right: 30px;

                    img {
                      height: 200px;
                      width: auto;
                      object-fit: contain;
                      object-position: top;
                    }

                    @include media-breakpoint-down(xs) {
                        @include make-col(12);
                    }

                    p {
                        color: white;
                        line-height: 25px;
                        font-size: 18px;
                    }
                }

                p {
                    color: white;
                }
            }

            p {
                color: white;
            }
        }
    }

      .carousel-control-prev {
        .carousel-control-prev-icon {
            left: 185px;
            top: 224px;

            @media (max-width: 1817px) {
                left: 280px;
                top: 135px;
              }

            @media (max-width: 1440px)  {
                left: 455px;
                top: 135px;
            }

            @include media-breakpoint-down(lg) {
                top: 165px;
            }

            @include media-breakpoint-down(md) {
                left: 280px;
            }

            @include media-breakpoint-down(sm) {
                height: 40px;
                width: 40px;
                top: 120px;
                left: 120px;
            }

            @media (max-width: 320px) {
              left: 100px;
            }
        }
    }

    .carousel-control-next {
        .carousel-control-next-icon {
            right: 185px;
            top: 224px;

            @media (max-width: 1817px) {
                right: 280px;
                top: 135px;
            }

            @media (max-width: 1440px)  {
                right: 455px;
                top: 135px;
            }

            @include media-breakpoint-down(lg) {
                top: 165px;
            }

            @include media-breakpoint-down(md) {
              right: 280px;
            }

            @include media-breakpoint-down(sm) {
              height: 40px;
              width: 40px;
              top: 120px;
              right: 120px;
            }

            @media (max-width: 320px) {
              right: 100px;
            }
        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      @include media-breakpoint-down(lg) {
        width: 40px !important;
        height: 40px !important;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url("../Images/layout/pfeil-grau-links-dunkel.svg") !important;
      }
    }
    
    .new-product-badge {
        position: absolute;
        background-color: $color-green;
        color: white;
        padding: 2px 10px;
        left: 18%;
        top: 27%;
        font-family: $font-family-medium;
        letter-spacing: 1px;
        font-size: 26px;
        animation: tilt-shaking 2s ease-in-out alternate infinite;
        
        @include media-breakpoint-down(md) {
            font-size: 22px;
            top: 38%;
            left: 8%;
        }
    }
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
  }