.cross-word-puzzle {
    position: relative;
    display: flex;
    
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }

    #puzzle {
        display: table-cell;
        width: 100%;
        margin: 0;
        padding: 10px;
        
        td {
            padding: 1px;
            border-top: none;
        }
    }
    
    .preview {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        margin: 45px 0 50px;
        background-color: #edecec;
        padding: 30px 25px;
        
        .neos-nodetypes-headline {
            width: 100%;
            
            h3 {
                font-family: $font-family-medium;
            }
        }
        
        .neos-nodetypes-text {
            margin-bottom: 0;
            width: 100%;
        }
        
        .preview-numbers {
            display: contents;

            ul {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 7px;
                padding-left: 0;
                line-height: normal;
                text-transform: uppercase;

                li {
                    margin-bottom: 0;
                    color: #707070;
                    border-bottom: 1px solid #707070;
                    margin: 3px;
                    width: 30px;
                    text-align: center;
                    font-size: 16px;
                    
                    @include media-breakpoint-down(sm) {
                        width: 20px;
                        font-size: 14px;
                    }
                    
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    
    %solution-number {
        background-color: rgba($color: #000000, $alpha: 0.2);
        position: relative;
        
        &::after {
            position: absolute;
            right: 1px;
            bottom: 0px;
            font-size: 12px;
            color: #555;
            line-height: normal;
            
            @include media-breakpoint-down(sm) {
                font-size: 10px;
            }
        }
    }
    
    &#puzzle1 {
        #puzzle {
            tr:nth-child(7) > td:nth-child(1) > div{
                @extend %solution-number;
                
                &::after {
                    content: "18";
                }
            }
            
            tr:nth-child(9) > td:nth-child(1) > div {
                @extend %solution-number;
                
                &::after {
                    content: "17";
                }
            }
            
            tr:nth-child(17) > td:nth-child(1) > div {
                @extend %solution-number;
                
                &::after {
                    content: "14";
                }
            }
            
            tr:nth-child(16) > td:nth-child(1) > div {
                @extend %solution-number;
                
                &::after {
                    content: "9";
                }
            }
            
            tr:nth-child(14) > td:nth-child(1) > div {
                @extend %solution-number;
                
                &::after {
                    content: "1";
                }
            }
            
            tr:nth-child(13) > td:nth-child(2) > div {
                @extend %solution-number;
                
                &::after {
                    content: "21";
                }
            }
            
            tr:nth-child(13) > td:nth-child(3) > div {
                @extend %solution-number;
                
                &::after {
                    content: "5";
                }
            }
            
            tr:nth-child(1) > td:nth-child(3) > div {
                @extend %solution-number;
                
                &::after {
                    content: "24";
                }
            }
            
            tr:nth-child(7) > td:nth-child(3) > div {
                @extend %solution-number;
                
                &::after {
                    content: "22";
                }
            }
            
            tr:nth-child(8) > td:nth-child(3) > div {
                @extend %solution-number;
                
                &::after {
                    content: "20";
                }
            }
            
            tr:nth-child(9) > td:nth-child(3) > div {
                @extend %solution-number;
                
                &::after {
                    content: "7";
                }
            }
            
            tr:nth-child(20) > td:nth-child(3) > div {
                @extend %solution-number;
                
                &::after {
                    content: "13";
                }
            }
            
            tr:nth-child(14) > td:nth-child(5) > div {
                @extend %solution-number;
                
                &::after {
                    content: "12";
                }
            }
            
            tr:nth-child(15) > td:nth-child(5) > div {
                @extend %solution-number;
                
                &::after {
                    content: "3";
                }
            }
            
            tr:nth-child(17) > td:nth-child(5) > div {
                @extend %solution-number;
                
                &::after {
                    content: "6";
                }
            }
            
            tr:nth-child(18) > td:nth-child(5) > div {
                @extend %solution-number;
                
                &::after {
                    content: "16";
                }
            }
            
            tr:nth-child(13) > td:nth-child(8) > div {
                @extend %solution-number;
                
                &::after {
                    content: "10";
                }
            }
            
           tr:nth-child(3) > td:nth-child(10) > div {
                @extend %solution-number;
                    
                &::after {
                    content: "19";
                }
           }
            
           tr:nth-child(4) > td:nth-child(10) > div {
                @extend %solution-number;
                    
                &::after {
                    content: "4";
                }
           }
            
            tr:nth-child(6) > td:nth-child(10) > div {
                @extend %solution-number;
                    
                &::after {
                    content: "2";
                }
           }
           
           tr:nth-child(10) > td:nth-child(10) > div {
                @extend %solution-number;
                        
                &::after {
                    content: "8";
                }
           }
           
           tr:nth-child(13) > td:nth-child(10) > div {
                @extend %solution-number;
                        
                &::after {
                    content: "15";
                }
           }
           
           tr:nth-child(17) > td:nth-child(10) > div {
                @extend %solution-number;
                            
                &::after {
                    content: "23";
                }
           }
        }
    }
    
    &#puzzle2 {
        #puzzle {
            tr:nth-child(1) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "12";
                }
            }
            
            tr:nth-child(14) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "21";
                }
            }
            
            tr:nth-child(19) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "20";
                }
            }
            
            tr:nth-child(20) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "18";
                }
            }
            
            tr:nth-child(8) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "10";
                }
            }
            
            tr:nth-child(9) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "9";
                }
            }

            tr:nth-child(10) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "19";
                }
            }

            tr:nth-child(15) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "17";
                }
            }

            tr:nth-child(16) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(17) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "6";
                }
            }

            tr:nth-child(19) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "4";
                }
            }

            tr:nth-child(18) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "22";
                }
            }

            tr:nth-child(16) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "11";
                }
            }

            tr:nth-child(6) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(7) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }

            tr:nth-child(10) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }
            
            tr:nth-child(12) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "14";
                }
            }

            tr:nth-child(15) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "8";
                }
            }

            tr:nth-child(18) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "16";
                }
            }

            tr:nth-child(19) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }
        }
    }
    
    &#puzzle3 {
        #puzzle {
            tr:nth-child(1) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "13";
                }
            }

            tr:nth-child(2) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }

            tr:nth-child(5) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }

            tr:nth-child(6) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "19";
                }
            }

            tr:nth-child(6) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "12";
                }
            }

            tr:nth-child(7) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "4";
                }
            }

            tr:nth-child(8) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "6";
                }
            }

            tr:nth-child(9) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "20";
                }
            }

            tr:nth-child(9) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "14";
                }
            }

            tr:nth-child(11) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "23";
                }
            }

            tr:nth-child(11) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(13) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(13) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "21";
                }
            }

            tr:nth-child(15) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "9";
                }
            }

            tr:nth-child(16) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "8";
                }
            }

            tr:nth-child(19) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }

            tr:nth-child(18) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "17";
                }
            }

            tr:nth-child(19) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "22";
                }
            }

            tr:nth-child(25) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "18";
                }
            }

            tr:nth-child(24) > td:nth-child(3) > div{
                @extend %solution-number;
                &::after {
                    content: "15";
                }
            }
        }
    }
    
    &#puzzle4 {
        #puzzle {
            tr:nth-child(2) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }

            tr:nth-child(3) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "17";
                }
            }

            tr:nth-child(4) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "15";
                }
            }

            tr:nth-child(6) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }

            tr:nth-child(7) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "13";
                }
            }

            tr:nth-child(7) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "11";
                }
            }

            tr:nth-child(8) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(10) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "14";
                }
            }

            tr:nth-child(13) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "6";
                }
            }

            tr:nth-child(12) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "10";
                }
            }

            tr:nth-child(6) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "21";
                }
            }

            tr:nth-child(8) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "16";
                }
            }

            tr:nth-child(10) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "20";
                }
            }

            tr:nth-child(11) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "4";
                }
            }

            tr:nth-child(17) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }

            tr:nth-child(19) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "9";
                }
            }

            tr:nth-child(16) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "22";
                }
            }

            tr:nth-child(17) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(20) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "18";
                }
            }

            tr:nth-child(20) > td:nth-child(4) > div {
                @extend %solution-number;
                &::after {
                    content: "19";
                }
            }

            tr:nth-child(20) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "8";
                }
            }
        }
    }
    
    &#puzzle5 {
        #puzzle {
            tr:nth-child(2) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "4";
                }
            }

            tr:nth-child(2) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }

            tr:nth-child(2) > td:nth-child(10) > div {
                @extend %solution-number;
                &::after {
                    content: "6";
                }
            }

            tr:nth-child(4) > td:nth-child(8) > div {
                @extend %solution-number;
                &::after {
                    content: "9";
                }
            }

            tr:nth-child(4) > td:nth-child(11) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }

            tr:nth-child(4) > td:nth-child(12) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(4) > td:nth-child(17) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(7) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "10";
                }
            }

            tr:nth-child(7) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }

            tr:nth-child(7) > td:nth-child(4) > div {
                @extend %solution-number;
                &::after {
                    content: "12";
                }
            }

            tr:nth-child(7) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "11";
                }
            }

            tr:nth-child(7) > td:nth-child(15) > div {
                @extend %solution-number;
                &::after {
                    content: "15";
                }
            }

            tr:nth-child(9) > td:nth-child(14) > div {
                @extend %solution-number;
                &::after {
                    content: "14";
                }
            }
        }
    }
    
    &#puzzle6 {
        #puzzle {
            tr:nth-child(3) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(3) > td:nth-child(13) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }

            tr:nth-child(3) > td:nth-child(19) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(2) > td:nth-child(20) > div {
                @extend %solution-number;
                &::after {
                    content: "12";
                }
            }

            tr:nth-child(5) > td:nth-child(12) > div {
                @extend %solution-number;
                &::after {
                    content: "6";
                }
            }

            tr:nth-child(5) > td:nth-child(14) > div {
                @extend %solution-number;
                &::after {
                    content: "4";
                }
            }

            tr:nth-child(5) > td:nth-child(15) > div {
                @extend %solution-number;
                &::after {
                    content: "8";
                }
            }

            tr:nth-child(5) > td:nth-child(22) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }

            tr:nth-child(6) > td:nth-child(20) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }

            tr:nth-child(7) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "11";
                }
            }

            tr:nth-child(7) > td:nth-child(19) > div {
                @extend %solution-number;
                &::after {
                    content: "9";
                }
            }

            tr:nth-child(7) > td:nth-child(21) > div {
                @extend %solution-number;
                &::after {
                    content: "10";
                }
            }

            tr:nth-child(9) > td:nth-child(20) > div {
                @extend %solution-number;
                &::after {
                    content: "13";
                }
            }
        }
    }
    
    &#puzzle7 {
        #puzzle {
            tr:nth-child(2) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(3) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "6";
                }
            }

            tr:nth-child(5) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }

            tr:nth-child(6) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }

            tr:nth-child(7) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(5) > td:nth-child(3) > div {
                @extend %solution-number;
                &::after {
                    content: "8";
                }
            }

            tr:nth-child(5) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "9";
                }
            }

            tr:nth-child(8) > td:nth-child(1) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }
        }
    }
    
    &#puzzle8 {
        #puzzle {
            tr:nth-child(1) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "1";
                }
            }

            tr:nth-child(2) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "5";
                }
            }

            tr:nth-child(1) > td:nth-child(8) > div {
                @extend %solution-number;
                &::after {
                    content: "15";
                }
            }

            tr:nth-child(2) > td:nth-child(8) > div {
                @extend %solution-number;
                &::after {
                    content: "2";
                }
            }

            tr:nth-child(3) > td:nth-child(8) > div {
                @extend %solution-number;
                &::after {
                    content: "3";
                }
            }

            tr:nth-child(3) > td:nth-child(7) > div {
                @extend %solution-number;
                &::after {
                    content: "16";
                }
            }

            tr:nth-child(4) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "12";
                }
            }

            tr:nth-child(5) > td:nth-child(6) > div {
                @extend %solution-number;
                &::after {
                    content: "4";
                }
            }

            tr:nth-child(6) > td:nth-child(8) > div {
                @extend %solution-number;
                &::after {
                    content: "8";
                }
            }

            tr:nth-child(9) > td:nth-child(8) > div {
                @extend %solution-number;
                &::after {
                    content: "7";
                }
            }

            tr:nth-child(9) > td:nth-child(2) > div {
                @extend %solution-number;
                &::after {
                    content: "10";
                }
            }

            tr:nth-child(9) > td:nth-child(4) > div {
                @extend %solution-number;
                &::after {
                    content: "13";
                }
            }

            tr:nth-child(9) > td:nth-child(5) > div {
                @extend %solution-number;
                &::after {
                    content: "11";
                }
            }
        }
    }
    
    .square {
        width: 35px;
        height: 35px;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        position: relative;
        text-transform: uppercase;
        
        @include media-breakpoint-down(sm) {
            width: 30px;
            height: 30px;
        }
        
        &.disabled {
            background-color: #000000;
            color: transparent;
            border: none;
        }
    }

    .empty {
        background-color: white;
    }

    .letter {
        border: 1px solid #A1A1A2;
        text-align: center;
        cursor: pointer;
    }

    .puzzle-container {
        width: 40%;
        
        @media (max-width: 1200px) {
            margin-bottom: 25px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    
    &#puzzle5 {
        .puzzle-container {
            width: 55%;
            
            @media (max-width: 1200px) {
                width: 100%;
                overflow-x: scroll;
            }
        }
    }

    &#puzzle6 {
        .puzzle-container {
            width: 65%;
            
            @media (max-width: 1200px) {
                width: 100%;
                overflow-x: scroll;
            }
        }
    }
    
    .hints-buttons-container {
        width: 60%;
        margin-left: 30px;
        
        @media (max-width: 1200px) {
            width: 100%;
        }
        
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }
    
    &#puzzle5 {
        .hints-buttons-container {
            width: 45%;
            
            @media (max-width: 1200px) {
                width: 100%;
            }
        }
    }

    &#puzzle6 {
        .hints-buttons-container {
            width: 35%;
            
            @media (max-width: 1200px) {
                width: 100%;
            }
        }
    }

    .hints-container {
        
        .hint {
            font-size: 21px;
            display: flex;
            
            b {
                margin-right: 5px;
            }
            
            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 33px;
            }
        }
        .info {
            display: none;
            &.active {
                display: block;
                border-top: 1px solid #9b9b9b;
                padding-top: 25px;
                margin-top: 52px;
            }
            
            &.backend {
                display: block;
                margin-top: 50px;
    
                div {
                    display: block !important;
                }
            }
        }
        .info .headline-text--wrapper {
            display: none;
        }
        
        .info .headline-text--wrapper {
            &.active {
                display: block;
            }
        }
    }

    .buttons-container {
        margin-top: 30px;
        margin-bottom: 55px;

        a {
            cursor: pointer;
            font-size: 14px;
            &:first-child {
                margin-right: 20px;
                
                @include media-breakpoint-down(sm) {
                    margin-bottom: 15px;
                }
            }
            
            @include media-breakpoint-down(sm) {
                display: inline-block;
            }
        }
    }

    .active {
        border-color: $color-green;
    }

    .question_number {
        position: absolute;
        font-size: 12px;
        color: $color-green;
        left: 4px;
        top: 1px;
        font-family: $font-family-medium;
    }
    
    .vertical-hints-container {
        margin-bottom: 30px;
    }

    td {
        position: relative;
    }
    .message {        
        .correct, .try-again {
            display: none;
            
            &.active {
                display: block;
                color: white;
                padding: 15px;
                margin-top: 28px;
            }
        }
        
        .correct {
            &.active {
                background-color: #647e78;
            }
        }
        
        .try-again {
            &.active {
                background-color: #a5a5a5;
                
                span {
                    margin-left: 5px;
                }
            }
        }
    }
    
    #success-message-confetti {
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        opacity: 1;
        -webkit-transition: opacity 1000ms linear;
        transition: opacity 1000ms linear;
        
        @include media-breakpoint-down(sm) {
            height: 100vh;
        }
    }
    
    .confetti {
        width: 10px;
        height: 10px;
        position: absolute;
        animation: confetti-fall  5s linear infinite;
        opacity: 1;
        transition: opacity 2s ease-out; /* Adjust the fade-out duration */
      }
      
      @keyframes confetti-fall {
        0% {
          transform: translateY(0) rotate(0deg);
        }
        100% {
          transform: translateY(600px) rotate(360deg);
        }
      }
}
