.grid-content-col {
  &.inner-well {
    > .neos-contentcollection {
      @extend .well;
    }
  }

  @include media-breakpoint-down(md) {
    &.col-8{
        @include make-col(12);
      }

      &.col-2 {
        @include make-col(6);
      }

      &.col-4 {
        @include make-col(6);
      }
  }

  @include media-breakpoint-down(sm) {
    &.col-2 {
        @include make-col(6);
      }

      &.col-4 {
        @include make-col(12);
      }
  }
  
  &.md-full {
    &.col-6 {
      @include media-breakpoint-down(md) {
        @include make-col(12);
      }
    }
  }

  &.mt30 {
    margin-top: 30px;
  }

  &.mt50 {
    margin-top: 50px;
  }
  
  &.card {
    position: absolute;
    top: -38%;
    right: 0;
    border: none;
    background-color: transparent;
    
    @include media-breakpoint-down(lg) {
      top: -24%;
    }
    
    @include media-breakpoint-down(md) {
      position: relative;
      top: 0;
    }
    
    .neos-contentcollection {
      position: relative;
      background-color: white;
      box-shadow: 0px 4px 11px rgb(0 0 0 / 16%);
      border: none;
    
      
      > .headline-metaheadline--wrapper {
        padding: 35px 35px 0;
        
        @include media-breakpoint-down(sm) {
          padding: 20px 20px 0;
        }
      }
      
      > .neos-nodetypes-text {
        padding: 0 35px;
        
        @include media-breakpoint-down(sm) {
          padding: 0 20px;
        }
      }
      
      .grid-content-col {
        position: relative;
        background-color: $color-green;
        padding: 28px 35px;
        
        @include media-breakpoint-down(sm) {
          padding: 26px 20px;
        }
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          background-image: url('../Images/layout/icon-wissen-gluehbirne.svg');
          background-size: cover;
          background-repeat: no-repeat;
          height: 240px;
          width: 245px;
        }
        
        .neos-nodetypes-headline { 
          width: 72%;
          
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
          
          h2, h3 {
            color: white;
            font-family: $font-family-light;
            line-height: 37px;
            font-weight: 200;
            
            @include media-breakpoint-down(sm) {
              line-height: 30px;
            }
            
            strong {
              font-family: $font-family-medium;
            }
          }
        }
        
        .neos-nodetypes-html {
          margin-top: 40px;
        }
      }
    }
  }
}

.grid-content {
    @extend .container;
    margin-top: 70px;
    margin-bottom: 20px;

   &.no-margin {
        margin-bottom: 0;
    }

    &.no-margin-top {
        margin-top: 4px;
    }

    &.no-margin-top-null {
        margin-top: 0;
    }

    &.padding-top {
        padding-top: 50px !important;
    }

    &.padding-bottom {
      padding-bottom: 30px !important;
    }
    
    &.background-darker-gray {
      background-color: #cecdcd;
      padding: 50px 0;
    }
    
    &.newsletter-banner {
      margin-bottom: 0;
      margin-top: 4px;
    }
    
    &.relative {
      position: relative;
    }

    &.advent-image {
      background: #125148;
      background-repeat: no-repeat;
      position: relative;
      margin-top: 25px;
      margin-bottom: 5px;

      ~ .grid-content {
        margin-top: 25px;
      }

      &::after {
        content: url("../Images/layout/Santa_Claus.svg");
        position: absolute;
        width: 25%;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;

        @include media-breakpoint-down(lg) {
          width: 13%;
          height: auto;
          top: 17px;
          transform: translateX(-45%);
          left: 48%;
        }

        @include media-breakpoint-down(md) {
          width: 20%;
          transform: translateX(-49%);
        }

        @include media-breakpoint-down(sm) {
          top: 10px;
        }

        @media (max-width: 320px) {
          width: 26%;
        }
      }

      + .grid-content {
        padding: 0;
      }
    }
    
        
    &.background-blue-green {
      background-image: url("../Images/layout/hintergrund_flachen_3840x600.svg");
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;
      padding: 20px;
      
      .neos-nodetypes-text  {
        p {
          color: white;
        }
      }
      
      .neos-nodetypes-headline  {
        h2, h3, h4 {
          color: white;
        }
      }
    }

    &#webinar-form {
        display: none;
        position: relative;
        z-index: 50;

        &::before {
          background-image: url("../Images/layout/close_tip.svg");
          background-size: 40% 40%;
          background-position: center;
          background-repeat: no-repeat;
          width: 50px;
          content: attr(data-content);
          position: absolute;
          right: 0;
          top: 0;
          height: 50px;
          cursor: pointer;
          z-index: 200;
          background-color: $color-gray-dark;
        }

        &.active {
            position: fixed;
            top: 1%;
            left: 0;
            right: 0;

            &::after {
                content: "";
                display: block;
                position: fixed;
                padding: 100px 0;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(black, 0.5);
                z-index: 20;
            }
        }

        form {
            @extend .container-fluid;
            background-color: $color-gray-dark;
            padding: 50px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 50;

            @include media-breakpoint-down(lg) {
              padding: 15px;
              height: auto;
            }

            @include media-breakpoint-down(md) {
              height: 650px;
              overflow: scroll;
            }

            @include media-breakpoint-down(sm) {
              height: 620px;
            }

            @media (max-width: 320px) {
              height: 500px;
            }

            fieldset {
                @include make-col(6);

                @include media-breakpoint-down(md) {
                  @include make-col(10);
                }


                @include media-breakpoint-down(sm) {
                  @include make-col(12);
                }

                label {
                    color: white;
                    display: none;
                }

                input {
                    @include media-breakpoint-down(sm) {
                      height: 50px;
                    }
                }

                .form-group.contact-column.contact-dsgvo.input {
                    label {
                        display: flex;

                        input {
                            margin-top: 3px;
                        }

                        p {
                            display: inline;
                            font-size: $font-size-small * 1.1;
                            line-height: $font-size-small * 1.4;
                        }
                    }
                }

                   p {
                    @include media-breakpoint-down(sm) {
                      line-height: 30px;
                    }
                }
            }

            .actions {
                .form-navigation {
                    ul {
                        list-style: none;
                        padding-left: 0;

                        li {
                          button {
                            @include media-breakpoint-down(sm) {
                              max-width: 100%;
                            }
                          }
                        }
                    }
                }

                p {
                    display: none;
                }
            }
        }
    }

     &#bookletForm {
      display: none;
      position: relative;
      z-index: 50;

      &::before {
        background-image: url("../Images/layout/close_tip.svg");
        background-size: 40% 40%;
        background-position: center;
        background-repeat: no-repeat;
        width: 50px;
        content: attr(data-content);
        position: absolute;
        right: 0;
        top: 0;
        height: 50px;
        cursor: pointer;
        z-index: 200;
        background-color: $color-gray-dark;
      }

      &.active {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;

          &::after {
              content: "";
              display: block;
              position: fixed;
              padding: 100px 0;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: rgba(black, 0.5);
              z-index: 20;
          }
      }

      form {
          @extend .container-fluid;
          background-color: $color-gray-dark;
          padding: 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;

          @include media-breakpoint-down(lg) {
            padding: 15px;
            height: auto;
          }

          @include media-breakpoint-down(md) {
            height: 650px;
            overflow: scroll;
          }

          @include media-breakpoint-down(sm) {
            height: 620px;
          }

          @media (max-width: 320px) {
            height: 570px;
          }

          fieldset {
              color: white;
              @include make-col(8);

              @include media-breakpoint-down(md) {
                @include make-col(10);
              }


              @include media-breakpoint-down(sm) {
                @include make-col(12);
              }

              p {
                color: white;
              }

              label {
                  color: white;
                  display: none;
              }

              input {
                  @include media-breakpoint-down(sm) {
                    height: 50px;
                  }
              }

              .form-group.contact-column.contact-dsgvo.input {
                  label {
                      display: flex;

                      input {
                          margin-top: 3px;
                      }

                      p {
                          display: inline;
                          font-size: $font-size-small * 1.1;
                          line-height: $font-size-small * 1.4;
                      }
                  }
              }

                 p {
                  @include media-breakpoint-down(sm) {
                    line-height: 30px;
                  }
              }
          }

          .actions {
              .form-navigation {
                @include make-col(3);

                @include media-breakpoint-down(sm) {
                  @include make-col(12);
                }

                  ul {
                      list-style: none;
                      padding-left: 0;

                      li {
                        button {
                          @extend .btn-brand-primary;
                          border: none;
                          @include media-breakpoint-down(sm) {
                            max-width: 100%;
                          }
                        }
                      }
                  }
              }

              p {
                  display: none;
              }
          }
      }
     }

     &#form {
      display: none;
      position: relative;
      z-index: 50;

      &::before {
        background-image: url("../Images/layout/close_tip.svg");
        background-size: 40% 40%;
        background-position: center;
        background-repeat: no-repeat;
        width: 50px;
        content: attr(data-content);
        position: absolute;
        right: 0;
        top: 0;
        height: 50px;
        cursor: pointer;
        z-index: 200;
        background-color: $color-gray-dark;
      }

      &.active {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;

          &::after {
              content: "";
              display: block;
              position: fixed;
              padding: 100px 0;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: rgba(black, 0.5);
              z-index: 20;
          }
      }

      form {
          @extend .container-fluid;
          background-color: $color-gray-dark;
          padding: 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;

          @include media-breakpoint-down(lg) {
            padding: 15px;
            height: auto;
          }

          @include media-breakpoint-down(md) {
            height: 650px;
            overflow: scroll;
          }

          @include media-breakpoint-down(sm) {
            height: 620px;
          }

          @media (max-width: 320px) {
            height: 570px;
          }

          fieldset {
              color: white;
              @include make-col(8);

              @include media-breakpoint-down(md) {
                @include make-col(10);
              }


              @include media-breakpoint-down(sm) {
                @include make-col(12);
              }

              p {
                color: white;
              }

              label {
                  color: white;
                  display: none;
              }

              input {
                  @include media-breakpoint-down(sm) {
                    height: 50px;
                  }
              }

              .form-group.contact-column.contact-dsgvo.input {
                  label {
                      display: flex;

                      input {
                          margin-top: 3px;
                      }

                      p {
                          display: inline;
                          font-size: $font-size-small * 1.1;
                          line-height: $font-size-small * 1.4;
                      }
                  }
              }

                 p {
                  @include media-breakpoint-down(sm) {
                    line-height: 30px;
                  }
              }
          }

          .actions {
              .form-navigation {
                @include make-col(7);

                @include media-breakpoint-down(sm) {
                  @include make-col(12);
                }

                  ul {
                      list-style: none;
                      padding-left: 0;

                      li {
                        button {
                          @extend .btn-brand-primary;
                          border: none;
                          @include media-breakpoint-down(sm) {
                            max-width: 100%;
                          }
                        }
                      }
                  }
              }

              p {
                  display: none;
              }
          }
      }
     }

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
        margin-bottom: 45px;
    }
}

.grid-content.full {
    @extend .container-fluid;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    .grid-content-col {

      &.col-12 {
        padding-left: 0;
        padding-right: 0;
        
        &:has(.download-collection) {
          padding-left: 17px;
          padding-right: 17px;
        }

        .neos-nodetypes-image {
          img {
            width: 100%;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .grid-content-container {
        @extend .container;
    }

    &.background-gray {
        background-color: #4B575C;
        padding: 20px 0 9px;

        @include media-breakpoint-down(lg) {
          padding: 20px 0 5px;
        }

        @include media-breakpoint-down(md) {
          padding: 20px 0;
        }

        p, li {
            color: white;
        }

        &:last-child {
            margin-top: 4px;
        }

        .neos-nodetypes-headline {
          &.green {
            h2, h3, h4 {
              color: #3A9F55;
            }
          }
        }
    }

    &.background-blue {
        background-color:#4F707E ;
        padding: 20px 0 9px;

        @include media-breakpoint-down(lg) {
          padding: 20px 0 5px;
        }

        @include media-breakpoint-down(md) {
          padding: 20px 0;
        }

        p {
            color: white;
        }
    }

    &.background-light-grey {
        background-color: #D3D3D3;
        padding: 20px 0 9px;

        @include media-breakpoint-down(lg) {
          padding: 20px 0 5px;
        }

        @include media-breakpoint-down(md) {
          padding: 20px 0;
        }
    }

    &.contact-person {
        margin-bottom: 6px;
        margin-top: 0;
    }

    &.content-slider {
        .grid-content-container {
           @extend .container-fluid;
           max-width: 100vw;
        }
    }

    &.video-full {
        .grid-content-container {
            @extend .container-fluid;

            @include media-breakpoint-down(md) {
              max-width: 100%;
            }
        }
    }

    &.padding {
        .grid-content-col {

            &.col-12 {
                @include media-breakpoint-down(sm) {
                    padding: 0 17px !important;
                }
            }
        }
    }
  }

  .grid-content.image-full {
    @extend .container-fluid;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;

    .grid-content-container {
        @extend .container-fluid;
    }

    .grid-content-col {

        &.col-12 {
          padding-left: 0;
          padding-right: 0;

          .neos-nodetypes-image {
            img {
              width: 100%;
            }

            figure {
                margin-bottom: 0;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        .neos-nodetypes-image {
          figure {
            img {
              min-height: 500px;
              width: 100% !important;
              object-fit: cover;
              object-position: left center;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .neos-nodetypes-image {
          figure {
            img {
              min-height: 690px;
              width: 100% !important;
              object-fit: cover;
              object-position: left center;
            }
          }
        }
      }
  }

.container {
    @include media-breakpoint-up(lg) {
        width: 1370px;
        max-width: 100%;
      }

}
