    @font-face{
        font-family:"Vialog LT W02 Light";
        src:url("../Fonts/e2c36f81-25e4-4f4d-a5bb-31ecc2421da3.woff2") format("woff2"),url("../Fonts/cc8b3865-f853-422d-89c1-ebc5d94c2541.woff") format("woff");
    }

    @font-face{
        font-family:"Vialog LT W02 Regular";
        src:url("../Fonts/deeee52a-2f83-4638-86b3-632b3523020c.woff2") format("woff2"),url("../Fonts/f89e8886-d8ec-4f87-bc28-cbce4cb165ca.woff") format("woff");
    }

    @font-face{
        font-family:"Vialog LT W02 Medium";
        src:url("../Fonts/14f6ba6b-dee3-4cc8-8da2-4905aa8dce89.woff2") format("woff2"),url("../Fonts/1320b5d0-37cb-4cca-bec8-b1113fbddd26.woff") format("woff");
    }

    @font-face{
        font-family:"Vialog LT W02 Bold";
        src:url("../Fonts/e68f43d6-f845-42c2-bf3d-8203be3158b4.woff2") format("woff2"),url("../Fonts/38c9e9fe-ec23-45fa-97cc-4b5f8b90c464.woff") format("woff");
    }
