.tips--modal {

  .tips--modal-content {

    .carousel {

      .tips--carousel-item {
        width:1370px;
        height: 474px;
        color: white;
        padding: 51px 65px;

        @include media-breakpoint-down(lg) {
          width: 100%;
          height: 967px;
          padding: 75px 54px;
        }

        @include media-breakpoint-down(md) {
          height: 967px;
          padding: 64px 54px;
        }

        @include media-breakpoint-down(sm) {
          height: 1812px;
        }

        @media (max-width: 320px) {
          height: 2263px;
        }

        button.close {
          display: inline-block;
          margin: -1% auto 30px;
          transition: opacity 0.25s ease-in-out 0s;
          width: 75px;
          height: 75px;
          position: absolute;
          right: 0;
          top: 37px;
          border: none;
          background-color: transparent;
          background-repeat: no-repeat;
          background-image: url("../Images/layout/close_tip.svg");
          z-index: 1000;

          &:focus {
            outline: none;
          }
        }

        p {
          color: white;
        }
      }

      .carousel-control-prev {

        .carousel-control-prev-icon {
          width: 50px;
          height: 75px;
          position: absolute;
          left: 10px;
          top: 50%;
          background-repeat: no-repeat;
          background-image: url("../Images/layout/arrow_tip_right.svg");

          @include media-breakpoint-down(lg) {
            top: 158px;
          }
        }
      }

      .carousel-control-next {

        .carousel-control-next-icon {
          width: 50px;
          height: 75px;
          position: absolute;
          right: 10px;
          top: 50%;
          background-repeat: no-repeat;
          background-image: url("../Images/layout/arrow_tip_right.svg");
          transform: rotate(180deg);

          @include media-breakpoint-down(lg) {
            top: 158px;
          }
        }
      }
    }
  }
}
