.processplan {
    &.show-mobile  {
        @media (min-width: 990px) {
            display: none;
        }
    }
    
    &:not(.show-mobile) {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.process-plan--item {
    box-shadow: 0px 4px 11px #00000029;
    width: 50%;

    @media (max-width: 1440px) {
        width: 70%;
    }

    @include media-breakpoint-down(md) {
        width: 90%;
    }

    &:has(.second-col) {
        width: 50%;

        @media (max-width: 1440px) {
            width: 85%;

            &.show {
                transform: translate(-50%, 20%) !important;
            }
        }
    }

    &.woTable {
        position: relative;
        .white-content {
            padding: 35px 50px !important;
            overflow-x: scroll;
            max-height: 70vh;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70px;
            transition: 0.5s ease-in-out;
            content: "";
            background-image: linear-gradient(to top, rgba(255, 255, 255, 1), transparent);
        }
    }

    .white-content {
        padding: 35px;

        @include media-breakpoint-down(sm) {
            padding: 10px 18px 0;
        }

        .btn-brand-primary {
            background-color: unset;
            color: $color-black !important;
            padding: 0;
            font-family: $font-family-light;
            font-size: 18px;
            text-transform: none;
            border-bottom: 1px solid $color-black;
            border-radius: 0px;
            letter-spacing: 0;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }

        &:nth-child(2) {
            padding-bottom: 0;
            .subheadline {
                h3 {
                    margin-bottom: 0;
                }
            }
        }

        &:last-child {
            padding: 0 35px 50px 35px;
            min-height: auto;

            @include media-breakpoint-down(sm) {
                padding: 10px 18px 0;
                min-height: 302px;
            }

            @media (max-width: 320px) {
                min-height: 324px;
            }
        }

        h3 {
            margin-top: 0;
            margin-bottom: 14px;
        }
    }

    .requirements,
    .hermetic-solution {
        div {
            h4 {
                padding-top: 24px;
                padding-left: 35px;
                color: white;
                margin-bottom: 20px;
                font-size: 18px;
                font-family: $font-family-medium;
                
                @include media-breakpoint-down(md) {
                    padding: 10px 18px 0;
                    margin-bottom: 7px;
                }
            }
        }
    }

    .requirements {
        display: block;
        background-image: url("../Images/layout/background-green.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin: 14px 0 24px;

        &:has(.second-col) {
            display: grid;
            grid-template-columns: 50% 50%;
            
            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
            }

            div {
                tbody {
                    padding-top: 0;
                }

                &:nth-child(2) {
                    border-left: 1px solid white;
                    
                    @include media-breakpoint-down(md) {
                        border-left: none;
                    }
                }
            }
        }
    }

    .hermetic-solution {
        margin: 14px 0 0;

        &:has(.second-col) {
            display: grid;
            grid-template-columns: 50% 50%;
            
            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
            }

            .second-col {
                border-left: 1px solid $color-black;
                
                @include media-breakpoint-down(md) {
                    border-left: none;
                }
            }

            .table-attributes.white {
                padding-bottom: 18px;
            }
        }

        div {
            h4 {
                color: $color-black;
                padding-left: 0;
                padding-bottom: 0;
                padding-top: 5px;
            }
        }

        &:has(.second-col) {
            div {
                &:first-child {
                    padding-right: 35px;
                }

                &:nth-child(2) {
                    padding-left: 35px;
                    @include media-breakpoint-down(md) {
                        padding-left: 0;
                    }
                }
            }
        }

        .table-attributes.white {
            margin: 0;
        }
    }

    figure {
        margin-bottom: 0;
        img {
            width: 100%;
            height: 218px;
            object-fit: cover;
        }
    }

    .head {
        div {
            &:first-child {
                h3 {
                    font-size: 18px;
                    font-family: $font-family-light;
                    text-transform: uppercase;
                    letter-spacing: 0.06rem;
                    line-height: 1.5625;
                    color: #6e6e6d;
                    margin-bottom: 4px;
                    display: block;

                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }
                }
            }

            &:last-child {
                h3 {
                    font-family: $font-family-regular;
                    line-height: 32px;
                    margin-bottom: 24px;

                    @include media-breakpoint-down(sm) {
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .subheadline {
        h3 {
            color: #3a9f55;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            line-height: normal;
            font-family: $font-family-medium;
            margin-top: 0;
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
            font-size: 18px;
        }
    }

    .table-attributes {
        color: white !important;
        display: inline-flex;
        line-height: 22px;
        background-image: none;
        margin: 0;

        &:nth-child(3) {
            min-height: 225px;

            @media (max-width: 375px) {
                min-height: 245px;
            }

            @media (max-width: 320px) {
                min-height: 290px;
            }
        }

        tbody {
            padding: 18px 35px;
            width: 100%;

            @include media-breakpoint-down(sm) {
                padding: 18px;
            }

            tr {
                width: 100%;
                display: inline-table;

                &:first-child {
                    td {
                        border-top: 1px solid #3a9a61;
                    }
                }

                td {
                    padding: 5px 0px;
                    font-size: 18px;
                    border-top: 0;
                    border-bottom: 1px solid #3a9a61;

                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }

                    &:first-child {
                        width: 42%;

                        @include media-breakpoint-down(lg) {
                            width: 50%;
                        }
                    }
                }
            }
        }

        &.white {
            background-color: transparent;
            background-image: none;
            color: $color-black !important;
            margin: 0 0 24px;

            tbody {
                padding: 0;

                tr {
                    &:first-child {
                        td {
                            border-top: 1px solid #9b9b9b;
                        }
                    }

                    td {
                        border-bottom: 1px solid #9b9b9b;
                        word-break: break-word;
                        hyphens: auto;

                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .close {
        position: absolute;
        right: -25px;
        top: -25px;
        background-color: #00722e;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            background-color: white;
            height: 3px;
            width: 28px;
            transform-origin: top left;
            border-radius: 5px;

            &:nth-child(1) {
                transform: rotate(45deg) translateX(-50%) translateY(-50%);
                top: 50%;
            }

            &:nth-child(2) {
                transform: rotate(-45deg) translateX(-50%) translateY(-50%);
            }
        }
    }
}
#overlay-wrapper {
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    z-index: 99;
}

.overlay-backend {
    position: relative !important;
    z-index: 10 !important;
}

.overlay {
    &.active {
        &::after {
            content: "";
            display: block;
            position: fixed;
            padding: 100px 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(black, 0.5);
            z-index: 20;
        }
    }

    .process-plan--item {
        display: none;

        &.backend {
            display: block !important;
        }

        &.show {
            display: block;
            z-index: 99;
            position: absolute;
            background-color: white;
            left: 50%;
            top: 50%;
            transform: translate(-50%, 20%);
        }
    }
}
