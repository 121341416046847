.webinar-section {
    @include make-col(12);

    @include media-breakpoint-down(sm) {
        margin-top: 25px;
    }

    .webinar-head {
        h2 {
            font-size: 22px;
            color: $color-green;
        }

        h3 {
            @include make-col(9);
            color: $color-green;
            font-size: 40px;
            margin-bottom: 20px;
            margin-left: 0;

            @include media-breakpoint-down (md) {
                @include make-col(12);
            }

            p {
                font-size: 40px;
                color: $color-green;
                line-height: 50px;

                @include media-breakpoint-down(md) {
                  font-size: 28px;
                  line-height: 30px;
                  margin-bottom: 0px;
              }

                @include media-breakpoint-down(xs) {
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .webinar-head-content {
        background-image: url("../Images/layout/webinar-green-bg.svg");
        background-size: cover;
        min-height: 205px;
        background-repeat: no-repeat;

        p {
            @extend .neos-nodetypes-text;
            color: white;
            padding: 30px 25px;
            margin-bottom: 0;
        }
    }
}

.webinar-content {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 45px;
    @extend .neos-nodetypes-text;

    @include media-breakpoint-down(md) {
        display: block;
    }

    .webinar-information-event {
        margin-top: 28px;

        table {
            margin-top: 30px;

            tr > td {
                padding: 0;
                border: none;
                font-size: 21px;

                @include media-breakpoint-down(sm) {
                    font-size: 18px;
                }
            }
        }
    }
}

.webinar-information {
    @include make-col(8);
    padding-right: 24px;

    @include media-breakpoint-down(lg) {
        @include make-col(6);
    }

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }

    span {
        font-family: $font-family-medium;
        font-size: 21px;
    }

    p {
        margin-top: 10px;
    }

    ul {
        list-style: none;
        li {
            &::before {
                content: "\25AA";
                font-size: 1.7rem;
                font-weight: bold;
                display: inline-block;
                width: 19px;
                margin-left: -19px;
                position: absolute;
            }
        }
    }
    .content {
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.webinar-content-section {
    @include make-col(4);

    @include media-breakpoint-down(lg) {
        @include make-col(6);
    }

    @include media-breakpoint-down(md) {
        @include make-col(12);
    }

    .neos-nodetypes-image {
        img {
            width: 100%;
        }

        figure {
            margin-bottom: 10px;
        }
    }

    .second-trainer {
        margin-top: 30px;

        img {
            margin-bottom: 10px;
        }
    }

    .trainer-info {
        background-color: $color-green;
        padding: 25px 20px;

        p {
            color: white;
        }

        p:last-child{
            margin-bottom: 0;
        }
    }
}
