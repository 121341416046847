.neos-nodetypes-image {
    &.download {
        figcaption {
            position: relative;

            p {
                @extend .neos-nodetypes-text;
                color: white;
                margin-top: 10px;
            }
        }
    }

    .backend {
        position: relative;
    }

    &.black-figcaption {
      figcaption {
        p {
          color: black;
        }
      }
    }
}
