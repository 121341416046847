.table-attributes {
    background-image: url("../Images/layout/background-green-teaser@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white !important;
    display: inline-flex;
    
    tbody {
        padding: 45px 35px;
        width: 100%;
        
        @include media-breakpoint-down(sm) {
            padding: 18px;
        }
        
        tr {
            width: 100%;
            display: inline-table;
            
            &:first-child {
                td {
                    border-top: 1px solid #3A9A61;
                }
            }
            
            td {
                padding: 5px 0px;
                font-size: 18px;
                border-top: 0;
                border-bottom: 1px solid #3A9A61;
                
                &:first-child {
                    width: 45%;
                    
                    @include media-breakpoint-down(lg) {
                        width: 55%;
                    }
                    
                    @include media-breakpoint-down(sm) {
                        width: 52%;
                    }
                }
            }
        }
    }
}
