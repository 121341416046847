.callout {
    margin: 0 auto !important;

    .jonnitto-prettyembed-wrapper {
        height: auto;

        video {
            height: 500px;
            object-fit: cover;
        }
    }

    .carousel-item {
        height: 250px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $brand-secondary;
    }

    .pump-types {
        &::after {
            content: "";
            position: absolute;
            right: 258px;
            bottom: -3px;
            height: 349px;
            width: 447px;
            background-repeat: no-repeat;
            background-image: url("../Images/layout/pumpentypen.svg");

            @media (max-width: 1440px) {
                right: 30px;
            }

            @include media-breakpoint-down(lg) {
                background-size: contain;
                height: 249px;
                width: 314px;
            }

            @include media-breakpoint-down(md) {
                background-size: contain;
                height: 190px;
                width: 239px;
            }

            @include media-breakpoint-down(sm) {
                background-size: contain;
                height: 103px;
                width: 128px;
            }
        }
    }

    .pumping-media {
        &::after {
            content: "";
            position: absolute;
            right: 258px;
            bottom: -3px;
            height: 349px;
            width: 447px;
            background-repeat: no-repeat;
            background-image: url("../Images/layout/fourdermedien.svg");

            @media (max-width: 1440px) {
                right: 30px;
            }

            @include media-breakpoint-down(lg) {
                background-size: contain;
                height: 249px;
                width: 314px;
            }

            @include media-breakpoint-down(md) {
                background-size: contain;
                height: 190px;
                width: 239px;
            }

            @include media-breakpoint-down(sm) {
                background-size: contain;
                height: 103px;
                width: 128px;
            }
        }
    }

    .pump-design {
        &::after {
            content: "";
            position: absolute;
            right: 258px;
            bottom: -3px;
            height: 349px;
            width: 447px;
            background-repeat: no-repeat;
            background-image: url("../Images/layout/pumpenauslegung.svg");

            @media (max-width: 1440px) {
                right: 30px;
            }

            @include media-breakpoint-down(lg) {
                background-size: contain;
                height: 249px;
                width: 314px;
            }

            @include media-breakpoint-down(md) {
                background-size: contain;
                height: 190px;
                width: 239px;
            }

            @include media-breakpoint-down(sm) {
                background-size: contain;
                height: 103px;
                width: 128px;
            }
        }
    }

    .applications {
        &::after {
            content: "";
            position: absolute;
            right: 258px;
            bottom: -3px;
            height: 349px;
            width: 447px;
            background-repeat: no-repeat;
            background-image: url("../Images/layout/applikationen.svg");

            @media (max-width: 1440px) {
                right: 30px;
            }

            @include media-breakpoint-down(lg) {
                background-size: contain;
                height: 249px;
                width: 314px;
            }

            @include media-breakpoint-down(md) {
                background-size: contain;
                height: 190px;
                width: 239px;
            }

            @include media-breakpoint-down(sm) {
                background-size: contain;
                height: 103px;
                width: 128px;
            }
        }
    }

    .training {
        &::after {
            content: "";
            position: absolute;
            right: 258px;
            bottom: -3px;
            height: 349px;
            width: 447px;
            background-repeat: no-repeat;
            background-image: url("../Images/layout/tipps_und_training.svg");

            @media (max-width: 1440px) {
                right: 30px;
            }

            @include media-breakpoint-down(lg) {
                background-size: contain;
                height: 249px;
                width: 314px;
            }

            @include media-breakpoint-down(md) {
                background-size: contain;
                height: 190px;
                width: 239px;
            }

            @include media-breakpoint-down(sm) {
                background-size: contain;
                height: 103px;
                width: 128px;
            }
        }
    }

    .hermetic {
        &::after {
            content: "";
            position: absolute;
            right: 258px;
            bottom: -3px;
            height: 349px;
            width: 447px;
            background-repeat: no-repeat;
            background-image: url("../Images/layout/hermetic.svg");

            @media (max-width: 1440px) {
                right: 30px;
            }

            @include media-breakpoint-down(lg) {
                background-size: contain;
                height: 249px;
                width: 314px;
            }

            @include media-breakpoint-down(md) {
                background-size: contain;
                height: 190px;
                width: 239px;
            }

            @include media-breakpoint-down(sm) {
                background-size: contain;
                height: 103px;
                width: 128px;
            }
        }
    }

    .item {
        height: 376px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .callout-caption {
            position: relative;

            .callout-caption-box {
                position: absolute;
                bottom: 33%;
                background-color: transparent;

                p {
                    color: white;
                    font-size: 40px;
                    margin-bottom: 0;
                    line-height: 48px;

                    @include media-breakpoint-down(md) {
                        font-size: 30px;
                        line-height: 34px;
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: 25px;
                    }
                }
            }
        }

        &.green-black {
            .callout-caption-box {
                p {
                    color: $color-black;

                    &:first-child {
                        color: $color-green-00;
                        margin-bottom: 25px;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            height: 290px;
            background-size: cover;
            background-position: left;
        }

        @include media-breakpoint-down(md) {
            height: 235px;
            background-size: cover;
            background-position: left;
        }

        @include media-breakpoint-down(sm) {
            height: 150px;
            background-size: cover;
            background-position: left;
        }
    }
    .callout-caption {
        @extend .container;
        height: 100%;
    }

    .callout-caption-box {
        display: none;
        position: absolute;
        bottom: 30px;
        padding: 10px 20px;
        background-color: $callout-caption-bgcolor;
        color: $color-gray;

        @include media-breakpoint-up(sm) {
            display: inline-block;
            // position: absolute;
            // top: 100%;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;

            p {
                margin-bottom: 0;
            }
        }
    }
}
