.neos-nodetypes-image {
    &.teaser-image {
        margin-top: 0;
        margin-bottom: 30px;
        display: inline-block;
        position: relative;

        @include media-breakpoint-down(md) {
            // @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding-left: 0;
            padding-right: 0;
        }

        figure {
            position: relative;
            margin: auto;
            overflow: hidden;

            img {
                transition: all 0.2s;
                display: block;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }
            }

            figcaption {
                width: 100%;
                height: 100%;

                @include media-breakpoint-down(md) {
                    min-height: auto;
                }

                p {
                  font-style: normal;
                  background-color: rgba($color: #000000, $alpha: 0.6);
                  width: 320px;
                  font-size: 25px;
                  text-align: center;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 0;
                  text-align: center;
                  padding: 14px 8px;
                  color: white;
                  left: 0;
                  right: 0;
                  top: 106px;
                  position: absolute;

                  @include media-breakpoint-down(lg) {
                      font-size: 20px;
                      width: 83%;
                      min-height: auto;
                      top: 58px;
                  }

                  @include media-breakpoint-down(md) {
                    top: 68px;
                    padding: 9px 8px;
                   }

                  @include media-breakpoint-down(sm) {
                    top: 88px;
                  }

                  @include media-breakpoint-down(xs) {
                    top: 84px;
                  }

                  @media (max-width: 425px) {
                    top: 90px;
                  }

                  @media (max-width: 375px) {
                    top: 72px;
                  }

                  @media (max-width: 320px) {
                    top: 58px;
                  }
                }
              }
          }
          .backend {
            @include make-col(4);
          }
      }
}
