.footer {
  @extend .container-fluid;
  padding: 36px 0;
  background-color: #E8E8E8;
  margin-top: 4px;
  
  &.no-margin-top {
    margin-top: 0;
  }
}

.footer-content {
  @extend .container;
  display: flex;

  .footer-nav {
    @include media-breakpoint-down(xs) {
        @include make-col(6);
      }

      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }

    a {
      color: #4D4D4C;

      &:hover {
        color: darken($text-color, 15%);
      }
    }
  }

  .footer-nav-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;

    li {
        padding-right: 70px;
    }

     @include media-breakpoint-down(md) {
        li {
            padding-right: 50px;
        }
       }

       @include media-breakpoint-down(sm) {
        margin-top: 10px;
          li {
              padding: 0;
              padding-right: 0;
              display: block;
          }
       }

       @include media-breakpoint-down(xs) {
        display: block;
        margin-top: 0;

        li {
           margin-top: 18px;

           &:first-child {
               margin-top: 0;
           }
        }
    }
  }


  .footer-social-media {
    @include media-breakpoint-up(sm) {
        @include make-col(4);
      }

      display: flex;
      justify-content: flex-end;

      a {
          margin-right: 30px;

          &:last-child {
              margin-right: 0;
          }
      }

      @include media-breakpoint-down(xs) {
        @include make-col(6);
        display: flex;

        a {
            margin-right: 20px;
        }
    }
  }
}


