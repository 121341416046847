//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 34px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: 'Vialog LT W02 Light', Tahoma, Arial,sans-serif;
$font-family-light: 'Vialog LT W02 Light', Tahoma, Arial,sans-serif;
$font-family-regular: 'Vialog LT W02 Regular', Tahoma, Arial,sans-serif;
$font-family-medium: 'Vialog LT W02 Medium', Tahoma, Arial,sans-serif;
$font-family-bold: 'Vialog LT W02 Bold', Tahoma, Arial,sans-serif;
$font-size-base: 1rem;
$font-size-small: 0.8rem;
$font-size-h1: 2rem;
$font-size-h2: 2rem;
$font-size-h3: $font-size-base;
$font-size-h4: 30px;
$font-size-h5: $font-size-base;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$line-height-base: 1.5625;
$headings-line-height: 1.1;
$headings-font-weight: $font-weight-bold;

// Colors
$color-white: #ffffff;
$color-black: #282828;
$color-green: #058036;
$color-green-00: #3A9F55;
$color-green-light: #ecf5ec;
$color-green-lighter: #d0e8d1;
$color-gray: #54555a;
$color-gray-dark: #4B575C;
$color-gray-medium: #707070;
$color-gray-medium-light: #9a9a9a;
$color-gray-light: #eaeaea;
$color-gray-lighter: #f9f9f9;
$brand-primary: $color-green;
$brand-secondary: $color-gray;
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$callout-caption-bgcolor: transparentize($color-white, 0.1);

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;


//Slick Slider
$slick-font-path: "../Fonts/" !default;