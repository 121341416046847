.download-collection {
  .downloads--assets {
    display: flex;
    flex-wrap: wrap;

      .download-asset {
        @include make-col(3);

            p {
              padding-right: 65px;
              margin-bottom: 26px;
            }


        @include media-breakpoint-down(lg) {
          @include make-col(4);
        }

        @include media-breakpoint-down(sm) {
          @include make-col(6);
        }

        @media (max-width: 320px) {
          @include make-col(12);
        }

        img {
          border: 1px solid #eaeaea;
          height: 200px;
          width: auto;
          object-fit: contain;
          object-position: top;

          @include media-breakpoint-down(sm) {
            max-height: 97px;
          }
        }
      }
  }

  &.five-elements {
    .download-asset {
      width: 20%;
      max-width: 20%;

      @include media-breakpoint-down(md) {
        @include make-col(4);
      }

      @include media-breakpoint-down(sm) {
        @include make-col(6);
      }
    }
  }

  &.two-elements {
    .download-asset {
      @include make-col(6);

      @include media-breakpoint-down(md) {
        @include make-col(12);
      }

      @include media-breakpoint-down(sm) {
        @include make-col(6);
      }
    }
  }
}
