
.banner-section {
    @extend .container;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(md) {
        display: block;
    }

    .pump-icon {
        position: fixed;
        left: 5%;
        top: 60%;
        z-index: 0;

        @media (max-width: 1847px) {
          display: none;
        }
    }

    .banner-content {
        @include make-col(9);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .neos-nodetypes-text {
          margin-bottom: 15px;
        }

        p {
            @extend p;
            margin-bottom: 2px;

            @include media-breakpoint-down(lg) {
              margin-bottom: 10px;
            }

            @include media-breakpoint-down(md) {
                line-height: 30px;
            }
        }

        h2, h3 {
            margin-bottom: 10px;
            margin-top: 5px;

            @include media-breakpoint-down(md) {
              margin-top: 0;
            }
        }
    }

    .banner-cta {
        @include make-col(3);
        position: absolute;
        right: 17px;
        bottom: 40%;
        margin-top: 10px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            position: relative;
            display: contents;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 10px;
    }
}
