.header-start-section {
    @include make-col(12);

    h1 {
        font-size: 40px;
        margin-bottom: 25px;

        @include media-breakpoint-down(md) {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 15px;
      }

        @include media-breakpoint-down(xs) {
            font-size: 25px;
            margin-bottom: 15px;
        }

        p {
            @extend h1;
        }
    }

    p {
        @extend .neos-nodetypes-text;
    }
}
