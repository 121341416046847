$header-logo-height-md: 60px;
$header-logo-height-xs: $header-logo-height-md/2;

.header {
  @extend .container-fluid;
  padding-top: 10px;
  padding-bottom: 22px;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  
  @include media-breakpoint-down(md) {
    min-height: 70px;
    padding-bottom: 10px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 54px;
  }

  .header-content {
    @extend .container;
    margin-top: 25px;
  }
}

.header-logo {
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
  @media (max-width: 1440px) {
    flex: 0 0 20%;
    max-width: 20%;
  }

  @include media-breakpoint-down(md) {
    // Align logo and toggle
    position: absolute !important;
    top: 10px;
  }

  img {
    height: $header-logo-height-xs;

    @include media-breakpoint-up(md) {
      height: $header-logo-height-md;
    }

    @include media-breakpoint-down(md) {
        height: 52px;
    }

    @include media-breakpoint-down(sm) {
        height: 32px;
    }
  }
}

.header-nav {
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(9);
  }

  @include media-breakpoint-down(lg) {
    @include make-col(12);
  }

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
    
  @media (max-width: 1440px) {
    flex: 0 0 80%;
    max-width: 80%;
  }
  
  @include media-breakpoint-down(md) {
    @include make-col(12);
  }

  a {
    color: inherit;

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  li {
    &.active > a,
    &.current > a {
      font-weight: 500;
      font-family: $font-family-medium;
      color: $color-gray-medium;
    }
  }
}

.header-nav-list,
.header-nav-sub {
  list-style-type: none;
}

.header-nav-list {
  justify-content: flex-end;
  display: flex;
  padding: 0;
  margin-bottom: 0;

  // Add extra padding to prevent list from overlaying logo

  @include media-breakpoint-down(lg) {
    justify-content: flex-start;
  }

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    display: block;
    overflow: scroll;
    height: 80vh;

    li {
        border-bottom: 1px solid $color-gray-dark;
        padding: 15px 0;
    }
  }

  @include media-breakpoint-up(md) {
    text-align: right;

    li {
      display: block;
      padding-right: 26px;
      font-size: 19px;
      color: #6E6E6D;
      text-align: left;

      &:last-child{
          padding-right: 0;
      }

      &.current {
          font-family: $font-family-medium;
      }
    }
  }
  
  @media (max-width: 1440px) {
    li {
      font-size: 17px;
    }
  }
}

.header-nav-sub {
  @include media-breakpoint-up(md) {
    text-align: right;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 10px;
  }

}

@include media-breakpoint-up(md) {
  .header-nav-sub {
    display: none;
    position: absolute;
    right: 0;
    padding: 0 $grid-gutter-width/2;
    margin: 10px 0;
    font-size: $font-size-small;

    li {
      padding-right: 5px;
    }
  }

  .active .header-nav-sub {
    display: block;
  }
}

.header-nav-toggle {
  @include make-col-offset(9);
  color: #000;
  
  button {
    color: #000;
  }

    .navbar-toggler {
        &:focus {
            outline: none;
        }

        position: absolute;
        right: 0;
        top: 10px;

        @include media-breakpoint-down(md) {
            top: 25px;
        }

        @include media-breakpoint-down(sm) {
            top: 10px;
        }
    }
}

@media (min-width: $grid-float-breakpoint) {
  .header-nav-content.collapse {
    display: block;
    visibility: visible;
    height: auto;
    overflow: visible;
  }
}
