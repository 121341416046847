.process-plan--item {
    .requirements {
        background-image: url("../Images/layout/hintergrund-flachen.svg") !important;
        background-position: right;
    }
    
    .table-attributes {
        tbody {
            tr {
                
                &:first-child {
                    td {
                        border-top: 1px solid white !important;
                    }
                }

                td {
                    border-bottom: 1px solid white !important;
                }
            }
        }
    }
}