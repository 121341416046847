.quick-link {

    margin-top: 64px;

    @include media-breakpoint-down(md) {
        margin-top: 0;
    }

    figure {
        text-align: center;
    }

    figcaption {
        p {
            text-transform: uppercase;
            margin-top: 15px;
            font-size: 18px;
            font-family: $font-family-medium;
            color: #4B575C;
            line-height: 23px;
        }
    }
}
