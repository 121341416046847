%form-title {

  h2, h3 {
    background-size: 16px 25px;
    color: $black;
    padding: 10px 25px;
    margin-bottom: 0;
  }
}

%form-title-inverted {

  h2, h3 {
    background-size: 16px 25px;
    color: $white;
    padding: 10px 25px;
    margin-bottom: 0;
  }
}