.headline-metaheadline--wrapper {
    position: relative;

    .neos-nodetypes-headline {
        h2 {
            font-size: 18px;
            font-family: $font-family-medium;
            text-transform: uppercase;
            letter-spacing: 0.06rem;
            line-height: 1.5625;
            margin-bottom: 4px;
            display: block;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }

        h3 {
            font-family: $font-family-light;
            font-size: $font-size-h2;
            text-transform: none;
            line-height: 45px;
            letter-spacing: .02em;
            margin-bottom: 30px;
        }
    }
}
