.pumping-media--wrapper {
    .grid-content.full.background-gray {
        margin-bottom: 0;
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    .grid-content {
        &:first-child {
            margin-bottom: 0;
        }
    }
    
    .hero-pump-header {

        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }

        h1, h2 {
            color: $color-green;
            font-size: $font-size-h2 !important;

            span {
                display: block;
            }
        }

        p {
            display: block;
            margin-bottom: 0
        }

        img {
            @include make-col(4);
        }

        figure {
            text-align: center;
        }
    }

    .hero-pump-content {
        .container {
            display: flex;
            padding-right: 0;
            padding-left: 0;

            @include media-breakpoint-down(md) {
                display: block;
                .column-two {
                    margin-top: 50px;
                }
            }
        }

        .column-one,
        .column-two {
            // @extend .container;
            @include make-col(6);
            @extend .col;
            color: white;

            @include media-breakpoint-down(xs) {
                @include make-col(12);
            }

            h3 {
                color: #3a9f55;
                text-transform: uppercase;
                font-size: 23px;
                letter-spacing: 0.1rem;
                font-family: $font-family-medium;
                margin-top: 23px;

                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }
            }
        }

        .column-one {
            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            div {
                p,
                li {
                    color: white;
                }

                h3 {
                    &:first-child {
                        margin-top: 0 !important;
                    }
                }
            }

            ul {
                list-style: none;
                li {
                    &::before {
                        content: "\25AA";
                        font-weight: bold;
                        display: inline-block;
                        width: 19px;
                        margin-left: -19px;
                    }
                    @include media-breakpoint-down(md) {
                        margin-bottom: 2px;
                    }

                    a {
                        color: white;
                        text-decoration: none;
                        position: relative;

                        &:hover {
                            color: #f5f5f5;
                        }
                    }

                    a::after {
                        content: "";
                        position: absolute;
                        bottom: 2px;
                        left: 0;
                        display: inline-block;
                        width: 100%;
                        border-bottom: 1px solid white;
                    }
                }
            }
        }

        .column-two {
            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            .videos {
                margin-bottom: 33px;
            }

            .downloads--assets {
                display: flex;
                flex-wrap: wrap;

                @include media-breakpoint-down(sm) {
                    display: block;
                }

                .download-asset {
                    @include make-col(4);
                    margin-right: 30px;

                    img {
                        height: 200px;
                        width: auto;
                        object-fit: contain;
                        object-position: top;
                    }

                    @include media-breakpoint-down(xs) {
                        @include make-col(12);
                    }

                    p {
                        color: white;
                        line-height: 25px;
                        font-size: 18px;
                    }
                }

                p {
                    color: white;
                }
            }

            p {
                color: white;
            }
        }
    }

    .carousel-control-prev {
        .carousel-control-prev-icon {
            left: 185px;
            top: 224px;

            @media (max-width: 1817px) {
                left: 280px;
                top: 135px;
            }

            @media (max-width: 1440px) {
                left: 455px;
                top: 135px;
            }

            @include media-breakpoint-down(lg) {
                top: 165px;
            }

            @include media-breakpoint-down(md) {
                left: 280px;
            }

            @include media-breakpoint-down(sm) {
                height: 40px;
                width: 40px;
                top: 120px;
                left: 120px;
            }

            @media (max-width: 320px) {
                left: 100px;
            }
        }
    }

    .carousel-control-next {
        .carousel-control-next-icon {
            right: 185px;
            top: 224px;

            @media (max-width: 1817px) {
                right: 280px;
                top: 135px;
            }

            @media (max-width: 1440px) {
                right: 455px;
                top: 135px;
            }

            @include media-breakpoint-down(lg) {
                top: 165px;
            }

            @include media-breakpoint-down(md) {
                right: 280px;
            }

            @include media-breakpoint-down(sm) {
                height: 40px;
                width: 40px;
                top: 120px;
                right: 120px;
            }

            @media (max-width: 320px) {
                right: 100px;
            }
        }
    }
    
    .banner {
        height: 485px;
        background-repeat: no-repeat;
        background-size: cover;
        
        @include media-breakpoint-down(md) {
            height: 400px;
        }
        
        @include media-breakpoint-down(sm) {
            height: 300px;
        }
    }
}