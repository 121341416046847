.download-section {
    position: relative;

    ul {
        list-style: square;
    }

    &.abstracts {
        .download-text {
            font-size: 18px;
            padding-bottom: 0;
            margin-bottom: 30px;

            &::after {
                content: none;
            }
        }

        .download-left {
            display: none;
        }

        .download-right {
            ul {
                margin-left: 26px !important;
            }

            &.login {
                ul {
                    margin-left: 0 !important;
                }

                li {
                    @extend .btn-brand-primary;
                    &::before {
                        content: none;
                    }
                }

                a {
                }
            }
        }
    }

    &.product-info-pumps {
        .table-attributes {
            background-color: transparent;
            background-image: none;
            color: $color-black !important;
            
            tbody {
                padding: 0;
                
                tr {
                    &:first-child {
                        td {
                            border-top: 1px solid #9b9b9b;
                        }
                    }

                    td {
                        border-bottom: 1px solid #9b9b9b;
                        
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
            
        }
        
        .download-section-content {
            display: block;
            
            p {
                color: $color-green;
            }
            
            .download-right {
                ul {
                    margin-left: 25px;
                }
            }
        }
    }

    .line {
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 26px;
        color: $color-gray;
        font-family: $font-family-medium;
        line-height: 36px;

        @include media-breakpoint-down(lg) {
            line-height: 33px;
        }

        @include media-breakpoint-down(md) {
            line-height: 32px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-top: 1px solid #707070;
        }
    }

    .download-text {
        position: relative;
        padding-bottom: 26px;
        margin-bottom: 26px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-top: 1px solid #707070;
        }
    }
    .download-section-content {
        display: flex;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .download-right {
            @include make-col(8);

            @media (max-width: 1200px) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }

        .download-left {
            @include make-col(4);

            @media (max-width: 1200px) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            img {
                border: 1px solid #9a9a9a;
                height: 133px;
                width: auto;
            }
        }

        .download-right {
            ul {
                list-style: none;
                padding: 0;
                margin-left: 80px;
                margin-bottom: 0;

                @media (max-width: 1200px) {
                    margin-left: 50px;
                }

                @include media-breakpoint-down(md) {
                    margin-left: 25px;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                }

                li {
                    position: relative;
                    &::before {
                        content: "";
                        background-image: url("../Images/layout/download-arrow-green.svg");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        width: 11px;
                        height: 15px;
                        margin-right: 10px;
                        position: absolute;
                        top: 7px;
                        left: -26px;
                    }
                }
            }

            .dropdown {
                margin-left: 80px;
                position: relative;
                border-bottom: 1px solid $color-green;
                z-index: 0;

                @media (max-width: 1200px) {
                    margin-left: 50px;
                }

                @include media-breakpoint-down(md) {
                    margin-left: 26px;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                }

                button {
                    background-color: unset;
                    color: $color-green;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    width: 100%;
                    text-align: left;

                    &:focus,
                    &:active {
                        box-shadow: none !important;
                    }

                    &::before {
                        content: "";
                        background-image: url("../Images/layout/download-arrow-green.svg");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        width: 11px;
                        height: 15px;
                        margin-right: 10px;
                        position: absolute;
                        top: 10px;
                        left: -26px;
                    }

                    &.dropdown-toggle::after {
                        position: absolute;
                        right: 0;
                        top: 10px;
                    }

                    &[aria-expanded="true"] {
                        &.dropdown-toggle::after {
                            transition: transform 400ms;
                            transform: rotate(180deg);
                        }
                    }

                    &[aria-expanded="false"] {
                        &.dropdown-toggle::after {
                            transition: transform 400ms;
                        }
                    }
                }

                .dropdown-menu {
                    &.show {
                        width: 100%;
                        box-shadow: 0px 4px 11px #00000029;
                        border: none;
                        min-height: 50px;
                        max-height: 148px;
                        overflow-y: scroll;

                        .dropdown-item {
                            color: $color-green;
                            // background-color: red !important;
                        }

                        .dropdown-item.active,
                        .dropdown-item:active {
                            background-color: unset;
                        }
                    }
                }
            }
        }
    }
}

.download--wrapper {
    position: relative;
    background-color: white;
    box-shadow: 0px 4px 11px #00000029;
    color: #4d4d4c;
    padding: 40px;
    min-height: 281px;
    margin-bottom: 34px;

    h4 {
        max-width: 349px;

        &:first-child {
            color: #6e6e6d;
            text-transform: uppercase;
            font-size: 18px;
            font-family: $font-family-light;
            letter-spacing: 0.12em;
            margin-bottom: 12px;
        }

        &:nth-child(2) {
            margin-bottom: 40px;
            line-height: 40px;
        }
    }
}

.download-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .download-section {
        width: 49%;
        margin-left: 0px;
        margin-top: 0px;
        background-color: white;
        box-shadow: 0px 4px 11px #00000029;
        color: #4d4d4c;
        padding: 40px;
        min-height: 281px;
        margin-bottom: 30px;

        &:first-child {
            margin-left: 0;
        }

        @media (max-width: 1200px) {
            padding: 45px 25px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 17px;
            @include make-col(12);
            min-height: auto;
        }
    }
}
