.ccus-co2 {
    width: 100%;
    // @media (min-width: 2560px) {
    //     transform: scale(0.8);
    // }

    .pop-up {
        opacity: 0;
        display: none;
    }

    polyline, line {
        stroke-dasharray: 2338.5;
        stroke-dashoffset: 0;
        animation: lineAnimation 10s ease-in-out alternate infinite;
    }
    
    line {
        stroke-dasharray: 1000;
    }
    
    #factory, #liquid, #co2, #biomess, #transport-2, #offshore, #pump-3, #pump, #pump-7, #pump-5, #pump-4 {
        transform-origin: center center;
        transform-box: fill-box;
        cursor: pointer;
    }

    #factory, #liquid, #co2, #pump, #pump-5, #pump-4 {
        animation: pulse 5s ease-in-out alternate infinite;
    }
    
    #biomess, #transport-2, #offshore, #pump-3, #pump-7 {
        animation: pulse40 5s ease-in-out alternate infinite;
        animation-delay: 2s;
    }
    
    #biomass {
        position: relative;
        .pointer {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 35px;
            width: 35px;
            background-image: url('../Images/layout/left_click_google_font_icon.svg');
            background-repeat: no-repeat;
            background-size: contain;
            animation: pulse 1s ease-in-out alternate infinite;
            animation-delay: 2s;
            
        }
    }
    
    .content-group {
        circle, ellipse {
            transition: all ease-in 0.2s;
        }
        
        &:hover {
            circle, ellipse {
                fill: $color-green !important;
                transition: all ease-in 0.2s;
            }
            
            #icon-transport-2 {
                circle {
                    fill: white !important;
                }
            }
        }
    }
}

@keyframes lineAnimation {
    20%,
    100% {
        opacity: 1;
        stroke-dashoffset: 0;
    }
    0% {
        opacity: 0;
        stroke-dashoffset: -900;
    }
    10% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.06, 1.06);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes pulse40 {
    0% {
        transform: scale(1, 1);
    }

    40% {
        transform: scale(1.06, 1.06);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes pulse40 {
    0% {
        transform: scale(1, 1);
    }

    40% {
        transform: scale(1.06, 1.06);
    }

    100% {
        transform: scale(1, 1);
    }
}