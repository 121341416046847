.carousel {
  margin: $line-height-base auto;

  .carousel-control {
    background-image: none;
  }

  .carousel-control-icon {
    background-repeat: no-repeat;
    background-size: 45px;
    transition: opacity .1s;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  .carousel-item {
      height: 800px;
      overflow: hidden;
      transition: none;
      background-repeat: no-repeat;

      .webinar-section, .webinar-content {
          @extend .container;
      }
  }

  .carousel-indicators {
    bottom: 70px;
  }
}

#carousel-slider{
  .carousel-control-prev,
  .carousel-control-next {
    top: 50%;
    height: 35px;
    width: 0%;
    opacity: 0.85;

    &:hover {
        opacity: 1;
      }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 70px;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url("../Images/layout/pfeil-grau-links-dunkel.svg");
    }

    .carousel-control-prev-icon {
      left: 185px;

      @include media-breakpoint-down(sm) {
        top: -25px;

        @media (max-width: 1440px) {
            left: -9px;
        }
      }
    }

    .carousel-control-next-icon {
      transform: rotate(180deg);
      right: 185px;

      @include media-breakpoint-down(sm) {
        top: -25px;

        @media (max-width: 1440px) {
            right: -9px;
        }
      }
    }

    @include media-breakpoint-up(md) {

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        height: 70px;
      }

      .carousel-control-prev-icon {
        left: 185px;

        @media (max-width: 1440px) {
            left: -9px;
        }
      }

      .carousel-control-next-icon {
        right: 185px;

        @media (max-width: 1440px) {
            right: -9px;
        }
      }
    }
  }
  
  .callout-caption {
    height: 100%;
    display: flex;
    align-items: flex-end;
    
    p {
      margin-bottom: 0;
    }
  }

  .carousel-control-prev {
    left: -20%;
  }

  .carousel-control-next {
    right: -20%;
  }

  @media (max-width: 1440px) {

    .carousel-control-prev {
      left: 0;
    }

    .carousel-control-next {
      right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      height: 70px;
    }
  }

  .carousel-item {

    @include media-breakpoint-down(md) {
      background-size: contain;
      height: 390px;
    }

    @include media-breakpoint-down(sm) {
      background-size: contain;
      height: 196px;
    }
  }

  .carousel-indicators {
    @include media-breakpoint-down(sm) {
      bottom: 0;
    }
  }
}

#content-slider {
  .carousel-control-prev,
  .carousel-control-next {
    top: -150px;
    height: 35px;
    width: 0%;
    opacity: 0.85;

    &:hover {
        opacity: 1;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 70px;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url("../Images/layout/pfeil-grau-links-dunkel.svg");
    }

    .carousel-control-prev-icon {
      left: 0;
    }

    .carousel-control-next-icon {
      transform: rotate(180deg);
      right: 0;
    }

    @include media-breakpoint-up(md) {
      top: -200px;

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        height: 70px;
      }

      .carousel-control-prev-icon {
        left: -100px;
      }

      .carousel-control-next-icon {
        right: -100px;
      }
    }
    
    @include media-breakpoint-down(md) {
      top: -100px;
    }
    
    @include media-breakpoint-down(md) {
      top: -94px;
    }
    @include media-breakpoint-down(sm) {
      top: 37px;
    }
  }
}
