.case-study--item {
    box-shadow: 0px 4px 11px #00000029;
    
    .white-content {
        padding: 14px 35px;
        
        @include media-breakpoint-down(sm) {
            padding: 10px 18px 0;
        }
        
        .btn-brand-primary {
            background-color: unset;
            color: $color-black !important;
            padding: 0;
            font-family: $font-family-light;
            font-size: 18px;
            text-transform: none;
            border-bottom: 1px solid $color-black;
            border-radius: 0px;
            letter-spacing: 0;
            
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
        
        &:nth-child(2) {
            padding-bottom: 0;
            .subheadline {
                h3 {
                    margin-bottom: 0;
                }
            }
        }
        
        &:last-child {
            padding: 0 35px 40px;
            min-height: 281px;
            
            @include media-breakpoint-down(sm) {
                padding: 10px 18px 0;
                min-height: 302px;
            }
            
            @media (max-width: 320px) {
                min-height: 324px;
            }
        }
        
        h3 {
            margin-top: 0;
            margin-bottom: 14px;
        }
    }
    
    figure {
        margin-bottom: 0;
        img {
            width: 100%;
            height: 218px;
            object-fit: cover;
        }
    }
    
    .head {
        div {
            &:first-child {
                h3 {
                    font-size: 18px;
                    font-family: $font-family-light;
                    text-transform: uppercase;
                    letter-spacing: 0.06rem;
                    line-height: 1.5625;
                    color: #6E6E6D;
                    margin-bottom: 4px;
                    display: block;
                    
                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }
                }
            }
            
            &:last-child {
                h3 {
                    font-family: $font-family-regular;
                    line-height: 32px;
                    min-height: 65px;
                    margin-bottom: 24px;
                    
                    @include media-breakpoint-down(sm) {
                        line-height: 26px;
                        min-height: 50px;
                    }
                }
            }
        }
    }
    
    .subheadline {
        h3 {
            @extend .h3extra;
            font-size: 18px;
        }
    }
    
    .table-attributes {
        background-image: url("../Images/layout/background-green.svg");
        background-repeat: no-repeat;
        background-size: cover;
        color: white !important;
        display: inline-flex;
        margin: 14px 0 24px;
        line-height: 22px;
        
        &:nth-child(3) {
            min-height: 225px;
            
            @media (max-width: 375px) {
                min-height: 245px;
            }
            
            @media (max-width: 320px) {
                min-height: 290px;
            }
        }
        
        tbody {
            padding: 18px 35px;
            width: 100%;
            
            @include media-breakpoint-down(sm) {
                padding: 18px;
            }
            
            tr {
                width: 100%;
                display: inline-table;
                
                &:first-child {
                    td {
                        border-top: 1px solid #3A9A61;
                    }
                }
                
                td {
                    padding: 5px 0px;
                    font-size: 18px;
                    border-top: 0;
                    border-bottom: 1px solid #3A9A61;
                    
                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }
                    
                    &:first-child {
                        width: 45%;
                        
                        @include media-breakpoint-down(lg) {
                            width: 40%;
                        }
                        
                        @include media-breakpoint-down(sm) {
                            width: 45%;
                        }
                    }
                }
            }
        }
        
        &.white {
            background-color: transparent;
            background-image: none;
            color: $color-black !important;
            margin: 0 0 24px;
            
            tbody {
                padding: 0;
                
                tr {
                    &:first-child {
                        td {
                            border-top: 1px solid #9b9b9b;
                        }
                    }

                    td {
                        border-bottom: 1px solid #9b9b9b;
                        
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
