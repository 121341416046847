#content-slider.webinar--slider.carousel {
  margin-bottom: 50px;

  .carousel-indicators {
    display: flex;
    position: relative;
    top: 0;
    z-index: 0;
    margin-bottom: 70px;

    @include media-breakpoint-down(sm) {
      display: contents;
    }

    li {
      max-width: 130px;
      min-width: 130px;
      text-align: center;
      font-size: 18px;
      width: auto;
      text-indent: 0;
      background: none;
      padding: 15px 15px 0;
      line-height: 1.3;
      position: relative;

      @include media-breakpoint-down(sm) {
        padding-top: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 50px;
        height: 3px;
        transition: 0.3s;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-down(md) {
            transition: unset;
          }
      }

      &.active {
          &::before {
              content: '';
              position: absolute;
              top: 0;
              width: 50px;
              height: 3px;
              background-color: $color-green;


              @include media-breakpoint-down(sm) {
                width: 100px;
              }
          }
      }

      &:hover {
          position: relative;
          opacity: 1;
          &::before {
              content: '';
              position: absolute;
              top: 0;
              width: 50px;
              height: 3px;
              background-color: $color-green;
              transition: width 0.3s ease-in-out;

              @include media-breakpoint-down(md) {
                transition: unset;
              }

              @include media-breakpoint-down(sm) {
                width: 100px;
              }
          }
      }

      @include media-breakpoint-down(sm) {
        height: 18px;
        max-width: 100%;
      }
    }
  }

    .carousel-control-prev {
      top: 0;
      .carousel-control-prev-icon {
          left: 185px;
          top: 114px;

          @media (max-width: 1817px) {
            top: 0;
          }

          @include media-breakpoint-down(lg) {
            left: 5px;
          }

          @include media-breakpoint-down(md) {
            top: 0;
          }

          @include media-breakpoint-down(sm) {
            top: 56px;
          }
      }
  }

  .carousel-control-next {
      top: 0;
      .carousel-control-next-icon {
          right: 185px;
          top: 114px;

          @media (max-width: 1817px) {
            top: 0;
          }

          @include media-breakpoint-down(lg) {
            right: 5px;
          }

          @include media-breakpoint-down(md) {
            top: 0;
          }

          @include media-breakpoint-down(sm) {
            top: 56px;
          }
      }
  }
}
