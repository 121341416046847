.languages {
    @include make-col(12);
    display: flex;
    z-index: 30;
    height: 5px;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      transform: translateX(-40px);
    }

    @include media-breakpoint-down(sm) {
      top: 10px;
      transform: translateX(-10%);
    }

    @include media-breakpoint-down(xs) {
      transform: translateX(-47px);
    }

    @media (max-width: 1024px) {
      height: 0;
    }

        @include media-breakpoint-up(md) {
      top: 10px;
    }
  }

  .languages-list {
    margin-top: -7px;
    @extend .list-inline;
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }

    li {
      display: flex;

      a {
        font-size: 1rem;
        color: $color-gray-dark;

        &:hover {
          color: $black;
        }
      }
    }

    li + li:before {
      display: flex;
      content: '|';
      padding: 0 6px;

      @include media-breakpoint-down(md) {
          padding: 0 10px;
      }
    }

    a {
      @include media-breakpoint-up(sm) {
        color: $color-gray-dark;
      }
    }

    .current a {
       font-family: $font-family-medium;
    }

    @include media-breakpoint-down(md) {
        top: 6px;
     }

    @include media-breakpoint-down(sm) {
       top: -26px;
    }
  }
